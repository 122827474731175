@import "nav";
@import "menu_utility";
@import "search_suggestion";
@import "search_suggestion_overrides"; // EE2018-2 - added to remove prices from search results
@import "country_selector"; //remove this if multiple countries will not be used
@import "minicart";
@import "topasset";
@import "addtocart_modal";
@import "promobar";

$breakpoint-index: index(map-keys($grid-breakpoints), $breakpoint-name);
$next-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index + 1);

.main-menu {
    background-color: $white;

    @include media-breakpoint-up(lg) {
        margin-top: rem(40px);
    }
}

@include media-breakpoint-up($next-breakpoint) {
    .nav-item > .nav-link {
        color: $dark-grey;
    }
}

.logo-home .icon {
    width: 100%;
    height: 30px;
}
/*
*  custom large viewport condensed navigation media query
*  This is needed because the designed header does not reduce down
*  optimized for 1024px iPads
*/

@include respond(desktop) {
    .header-wrapper {
        max-width: $max-content-breakpoint;
        margin: 0 auto;
        position: relative;
    }
}

.header-search {
    background: $input-search-bg-color;
    color: $color-primary;
    box-sizing: border-box;
    height: rem($input-height-mobile);
    border: $input-search-border-mobile;
    border-color: $input-search-border-color;
    border-radius: $input-border-radius-mobile;
    padding-left: $input-padding-left-mobile;
    margin: 0 auto;
    text-indent: 0;
    width: 90%;
    @include respond($mobile-menu-breakpoint) {
        position: absolute;
        right: 0;
        top: 55px;
        width: rem(235px);
        z-index: 1;
        height: rem(45px);
        border: $input-search-border;
        border-radius: $input-border-radius-desktop;
        padding-left: $input-padding-left-desktop;
    }

    input[type="text"] {
        box-shadow: none;
        background-color: transparent;
        border: none;
        float: left;
        margin: 0;
        padding: 0;
        height: rem($input-height-mobile);
        @include p;
        color: $input-search-text-color;
        text-transform: none;
        width: 80%;
        // default for older browser
        width: calc(100% - 2rem);
        @include respond($mobile-menu-breakpoint) {
            @include alt-p;
            height: rem($input-search-line-height);
            border-radius: $input-border-radius-desktop;
            padding-left: 0;
            width: 85%;
            // default for older browser
            width: calc(100% - 2rem);
        }
        // placeholder gets different font style
        &::-webkit-input-placeholder {
            color: $input-search-text-color;
            text-transform: none;
            display: none;
            font-size: inherit;
        }
        &:-moz-placeholder, /* FF 19+ */
        &::-moz-placeholder { /* FF 18- */
            color: $input-search-text-color;
            text-transform: none;
            font-size: inherit;
        }
        &:-ms-input-placeholder {
            color: $input-search-text-color;
            text-transform: none;
            font-size: inherit;
        }
    }
    button {
        background-color: transparent;
        border: none;
        line-height: $input-search-line-height;
        padding: 0;
        margin: 0;
        width: $input-search-icon-width-mobile;
        @include respond($mobile-menu-breakpoint) {
            @include line-height($input-search-line-height);
            min-height: rem($input-search-line-height);
            width: $input-search-icon-width-desktop;
        }
        &:focus {
            box-shadow: none;
        }
        .icon {
            cursor: pointer;
            height: $input-search-icon-width-mobile;
            width: $input-search-icon-width-mobile;
            stroke: $input-search-icon-color;
            @include respond(desktop) {
                height: $input-search-icon-width-desktop;
                width: $input-search-icon-width-desktop;
                position: relative;
                top: -2px;
            }
        }
    }
}

.hp-seo a,
.category-seo a {
    text-decoration: underline;
    &:focus {
        color: #1b375d;
    }
    @media only screen and (max-width: $desktop-breakpoint) {
        text-decoration: underline;
    }
}
.content-wrapper a {
    overflow: hidden;
    display: block;
}

.sort-by .ui-selectmenu-button.shorterbox {
    margin: 0;
}
.amp-banner-container.rectangle .content-wrapper {
    overflow: visible !important;
}
@media only screen and (max-width: 767px) {
    .refinement-header {
        width: 100%;
        text-align: left;
        min-height: rem(50px);
    }
}
@media only screen and (max-width: 767px) {
    .refinements .category-refinement .categorymenugroup .menu-group-title-expand label::after {
        content: "+" !important;
    }
}
@media only screen and (max-width: 767px) {
    .refinements .category-refinement .categorymenugroup .menu-group.active .menu-group-title-expand label::after {
        content: "-" !important;
    }
}

//promobar
#rotating-banner-wrapper,
.rotating-banner-slot {
    background: #19365e;
}

.minicart {
    .minicart-total{
        padding-right: rem(2px);
    }
    .mini-cart-link {
        position: relative;
    }

    .minicart-quantity {
        background-color: transparent;
        color: $black;
        top: 73%;
        position: absolute;
        left: 50%;
        font-size: rem(12px);
        align-items: center;
        text-align: center;
        display: flex;
        justify-content: center;
        transform: translate(-50%, -50%);
    }
}   

.right-links {
    .store-right {
        margin-top: rem(10px);
        margin-right: rem(6px);
        .Utility_Store_Locator {
            top: 0;
        }
    }
}
.navbar-header .user .popover a {
    font-size: rem(14px);
}