@import "~base/components/headerSearch";

.site-search {
    @include media-breakpoint-up(lg) {
        width: rem(235px);
        margin-right: 0;
    }
    @include media-breakpoint-down(sm) {
        height: rem(45px);
    }
    input {
        color: $black-1;
        color: $dark-grey;
        height: rem(45px);
        border-radius: 0;
        padding-right: rem(47px);
        font-size: rem(14px);
        font-weight: 400;
        letter-spacing: rem(0.56px);
        &::-webkit-input-placeholder {
            color: $dark-grey;
            font-size: rem(14px);
            font-weight: 400;
            letter-spacing: rem(0.56px);
        }
        &:-moz-placeholder,
        &::-moz-placeholder {
            color: $dark-grey;
            text-transform: none;
            font-size: rem(14px);
            font-weight: 400;
            letter-spacing: rem(0.56px);
        }
        &:-ms-input-placeholder {
            color: $dark-grey;
            text-transform: none;
            font-size: rem(14px);
            font-weight: 400;
            letter-spacing: rem(0.56px);
        }
    }

    .fa-search {
        &.sg-search {
            &::before {
                display: none;
            }

            .Close {
                display: none;
            }
        }
    }

    .sg-search {
        top: rem(13px);
        right: rem(20px);
        display: flex;
        align-items: center;

        &.fa-close {
            top: rem(13px);
            right: rem(20px);
            display: flex;
            align-items: center;

            &::before {
                display: none;
            }

            .Close {
                display: none;
                margin-right: 10px;
            }
        }
    }

    &.active {
        .sg-search {
            &.fa-close {
                .Close {
                    display: none;
                    margin-right: 10px;
                }
            }
        }
    }

    .reset-button {
        top: rem(13px);
        right: rem(45px);
    }

    .Search {
        width: rem(20px);
        height: rem(20px);
    }

    .fa-times {
        &.reset-button {
            &::before {
                display: none;
            }
        }
    }

    .Close {
        width: rem(20px);
        height: rem(20px);
    }
}

@include media-breakpoint-down(md) {
    .search-mobile {
        .suggestions {
            position: fixed;
        }
    }
}
.search-mobile.search-for-fixed-header {
    .suggestions .item .name {
        @include media-breakpoint-down(sm) {
            max-width: 70vw;
        }
    }
}