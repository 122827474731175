.svg-Account_Addresses-dims {
	width: 55px;
	height: 55px;
}

.svg-Account_Comm_Pref-dims {
	width: 55px;
	height: 36.6px;
}

.svg-Account_Coupons-dims {
	width: 55px;
	height: 55px;
}

.svg-Account_Favorites-dims {
	width: 55px;
	height: 55px;
}

.svg-Account_Name-dims {
	width: 55px;
	height: 55px;
}

.svg-Account_Order_History-dims {
	width: 55px;
	height: 55px;
}

.svg-Account_Payments-dims {
	width: 55px;
	height: 55px;
}

.svg-Account_Privacy-dims {
	width: 43.2px;
	height: 56px;
}

.svg-Account_Who_Im_Shopping_For-dims {
	width: 55px;
	height: 55px;
}

.svg-Accross_devices-dims {
	width: 306.7px;
	height: 107.8px;
}

.svg-Alert-dims {
	width: 32px;
	height: 32px;
}

.svg-Arrow-dims {
	width: 55px;
	height: 55px;
}

.svg-Arrow_Down_small-dims {
	width: 55px;
	height: 55px;
}

.svg-Arrow_White-dims {
	width: 55px;
	height: 55px;
}

.svg-Arrow_down-dims {
	width: 55px;
	height: 55px;
}

.svg-Arrow_inline-dims {
	width: 55px;
	height: 55px;
}

.svg-Arrow_small-dims {
	width: 55px;
	height: 55px;
}

.svg-Arrow_up-dims {
	width: 55px;
	height: 55px;
}

.svg-Back_to_top-dims {
	width: 55px;
	height: 55px;
}

.svg-Checkbox_Checked-dims {
	width: 32px;
	height: 32px;
}

.svg-Checkbox_Checked_Disabled-dims {
	width: 32px;
	height: 32px;
}

.svg-Checkbox_Disabled-dims {
	width: 32px;
	height: 32px;
}

.svg-Checkout_Gift-dims {
	width: 55px;
	height: 55px;
}

.svg-Close-dims {
	width: 55px;
	height: 55px;
}

.svg-Close_Black-dims {
	width: 55px;
	height: 55px;
}

.svg-Close_Blue-dims {
	width: 55px;
	height: 55px;
}

.svg-Close_Circle-dims {
	width: 55px;
	height: 55px;
}

.svg-Favorites-dims {
	width: 55px;
	height: 55px;
}

.svg-Favorites_Filled_In-dims {
	width: 55px;
	height: 55px;
}

.svg-Floating_widget_free_shipping-dims {
	width: 130px;
	height: 130px;
}

.svg-Footer_Refer_A_Friend-dims {
	width: 55px;
	height: 55px;
}

.svg-Footer_Shipping_Services-dims {
	width: 130px;
	height: 130px;
}

.svg-Footer_gift_cards-dims {
	width: 130px;
	height: 130px;
}

.svg-Footer_gift_services-dims {
	width: 130px;
	height: 130px;
}

.svg-Footer_size_chart-dims {
	width: 130px;
	height: 130px;
}

.svg-GYM_Free_Returns-dims {
	width: 78.8px;
	height: 91.1px;
}

.svg-GYM_Truck_White_Outline-dims {
	width: 68.7px;
	height: 44px;
}

.svg-Gym_logo-dims {
	width: 593.2px;
	height: 145.9px;
}

.svg-Hamburger_Menu-dims {
	width: 55px;
	height: 55px;
}

.svg-Heart-dims {
	width: 55px;
	height: 55px;
}

.svg-Home-dims {
	width: 55px;
	height: 55px;
}

.svg-Instagram_Icon-dims {
	width: 55px;
	height: 55px;
}

.svg-JJ_Logo-dims {
	width: 483.83px;
	height: 35.2px;
}

.svg-JJ_sq_Logo-dims {
	width: 212px;
	height: 168px;
}

.svg-Logo-dims {
	width: 483.83px;
	height: 35.2px;
}

.svg-Low_Stock-dims {
	width: 55px;
	height: 55px;
}

.svg-More_Colors-dims {
	width: 31.81px;
	height: 31.81px;
}

.svg-Not_Available-dims {
	width: 55px;
	height: 55px;
}

.svg-PDP_Button-dims {
	width: 55px;
	height: 55px;
}

.svg-PDP_Details-dims {
	width: 55px;
	height: 55px;
}

.svg-PDP_Pin-dims {
	width: 55px;
	height: 55px;
}

.svg-PDP_Shirt-dims {
	width: 55px;
	height: 55px;
}

.svg-PinkLogo-dims {
	width: 483.83px;
	height: 35.2px;
}

.svg-Play_Button-dims {
	width: 55px;
	height: 55px;
}

.svg-Question_Mark-dims {
	width: 32px;
	height: 32px;
}

.svg-Radio_Selected-dims {
	width: 32px;
	height: 32px;
}

.svg-Review_Pencil-dims {
	width: 32px;
	height: 32px;
}

.svg-Review_Star_Empty-dims {
	width: 32px;
	height: 32px;
}

.svg-Review_Star_Full-dims {
	width: 32px;
	height: 32px;
}

.svg-Review_Star_Half-dims {
	width: 32px;
	height: 32px;
}

.svg-Search-dims {
	width: 55px;
	height: 55px;
}

.svg-Size_Chart-dims {
	width: 31.81px;
	height: 31.81px;
}

.svg-Social_Facebook-dims {
	width: 55px;
	height: 55px;
}

.svg-Social_Instagram-dims {
	width: 55px;
	height: 55px;
}

.svg-Social_Pinterest-dims {
	width: 55px;
	height: 55px;
}

.svg-Social_Snapchat-dims {
	width: 55px;
	height: 55px;
}

.svg-Star-dims {
	width: 68.5px;
	height: 78px;
}

.svg-Subcat_Grid_Icon1-dims {
	width: 55px;
	height: 55px;
}

.svg-Subcat_Grid_Icon2-dims {
	width: 55px;
	height: 55px;
}

.svg-Telephone-dims {
	width: 137.1px;
	height: 128px;
}

.svg-Utility_Bag-dims {
	width: 55px;
	height: 55px;
}

.svg-Utility_Login-dims {
	width: 55px;
	height: 55px;
}

.svg-Utility_Rewards-dims {
	width: 55px;
	height: 55px;
}

.svg-Utility_Store_Locator-dims {
	width: 55px;
	height: 55px;
}

.svg-Utility_Tracking-dims {
	width: 55px;
	height: 55px;
}

.svg-Utility_US_Flag-dims {
	width: 55px;
	height: 55px;
}

.svg-Wishlist-Favorites-dims {
	width: 55px;
	height: 55px;
}

.svg-Zoom-dims {
	width: 55px;
	height: 55px;
}

.svg-c8_logo-dims {
	width: 236.7px;
	height: 78.8px;
}

.svg-c8_logo_filled-dims {
	width: 351px;
	height: 170px;
}

.svg-check_circle-dims {
	width: 38px;
	height: 39.7px;
}

.svg-checkmark-dims {
	width: 12px;
	height: 12px;
}

.svg-circle_check-dims {
	width: 34.3px;
	height: 34.5px;
}

.svg-crazy8_TM-dims {
	width: 528px;
	height: 168.2px;
}

.svg-default_checkmark-dims {
	width: 12px;
	height: 12px;
}

.svg-dots-dims {
	width: 25px;
	height: 25px;
}

.svg-down-triangle-dims {
	width: 15px;
	height: 8px;
}

.svg-empty-cart-devices-dims {
	width: 306.7px;
	height: 107.8px;
}

.svg-envelope_small-dims {
	width: 612px;
	height: 432px;
}

.svg-gym_TM-dims {
	width: 626px;
	height: 145.9px;
}

.svg-icon_error-dims {
	width: 32px;
	height: 32px;
}

.svg-instagram-dims {
	width: 27.6px;
	height: 27px;
}

.svg-jj_TM-dims {
	width: 512.3px;
	height: 37.9px;
}

.svg-link-dims {
	width: 611px;
	height: 278px;
}

.svg-nav_promo_strip_shipping_icon-dims {
	width: 55px;
	height: 55px;
}

.svg-pin_large_dark-dims {
	width: 55px;
	height: 55px;
}

.svg-pin_large_light-dims {
	width: 55px;
	height: 55px;
}

.svg-pin_small_dark-dims {
	width: 55px;
	height: 55px;
}

.svg-pin_small_light-dims {
	width: 55px;
	height: 55px;
}

.svg-radio-dot-dims {
	width: 10px;
	height: 10px;
}

.svg-success_check-dims {
	width: 34.3px;
	height: 34.5px;
}

.svg-success_checkmark-dims {
	width: 20.4px;
	height: 20.5px;
}

.svg-world-dims {
	width: 21px;
	height: 15px;
}

.svg-you_saved-dims {
	width: 55px;
	height: 55px;
}

