// Divider Styles
.solid-grey {
    border-bottom: $divider-primary;
}

.section-title,
.section-title h2 {
    color: $navy;
}

/*
 * JJ has no border
*/
@mixin carat-divider {
    display: none;
}

@mixin footer-divider {
    display: none;
}

/*
 * C8 override is solid black underline
*/
@mixin accent-border {
    border-bottom: $divider-primary;
}

// Badge and Flag Styles

@mixin sale-tag {
    @include h7();
    color: $badge-sale-color;
}

.sale-tag {
    @include sale-tag();
}

@mixin online-tag {
    @include h7();
    color: $badge-online-color;
}

.online-tag {
    @include online-tag();
}

// promo ribbon
// borrowed from https://css-tricks.com/snippets/css/ribbon/
.badge-ribbon {
    @include promo4;
    background: $badge-ribbon-bg-color;
    color: $badge-ribbon-color;
    height: $badge-ribbon-height-mobile;
    @include line-height($badge-ribbon-height-mobile);
    text-align: center;
    position: relative;
    @include respond(large-up) {
        height: $badge-ribbon-height-desktop;
        @include line-height($badge-ribbon-height-desktop);
    }
}

// product tile flag
// do not use rem units; use px for accuracy
.badge-flag {
    @include promo5;
    background: $badge-flag-bg-color;
    border: 1px solid $badge-flag-color;
    color: $badge-flag-color;
    height: $badge-flag-height-mobile;
    @include line-height($badge-flag-height-mobile);
    text-transform: uppercase;
    position: relative;
    padding-left: rem(5px);
    width: 32%; // varies based use override as needed
    white-space: nowrap;
    @include respond(large-up) {
        height: $badge-flag-height-desktop;
        @include line-height($badge-flag-height-desktop);
    }
}

// Override for PDP Page
.badge-outfit {
    .badge-flag {
        display: inline-block;
        width: auto;
        padding-right: 0.5rem;
        padding-left: rem(7px);
        background: $badge-flag-pdp-bg-color;
        height: $badge-flag-pdp-height-mobile;
        @include line-height($badge-flag-pdp-height-mobile);
        @include respond(large-up) {
            float: left;
            width: auto;
            @include line-height($badge-flag-pdp-height-desktop);
            padding-left: rem(13px);
            padding-right: rem(13px);
            height: $badge-flag-pdp-height-desktop;
        }
    }
}

// section titles with underline
.section-title {
    text-align: center;
    h2,
    .h2 {
        margin-bottom: 0;
        @include h3();
    }
    span {
        display: none;
    }
}

// Horizonal Tab Styles
.horizontal-tabs #tabs {
    .tabs {
        margin: 0;
        padding: 0;
        float: left;
        list-style: none;
        height: 32px;
        width: 100%;
        li {
            @include h6;
            border: 0;
            margin: 0;
            padding: 0;
            float: left;
            cursor: pointer;
            overflow: hidden;
            position: relative;
            // 10px tall divider line
            &::after {
                content: "";
                border-right: $tab-horizontal-divider;
                font-size: $tab-horizontal-divider-height-mobile;
                margin-left: rem($tab-horizontal-padding-mobile);
                margin-right: rem($tab-horizontal-padding-mobile);
                @include respond(large-up) {
                    font-size: $tab-horizontal-divider-height-desktop;
                    margin-left: rem($tab-horizontal-padding-desktop);
                    margin-right: rem($tab-horizontal-padding-desktop);
                }
            }
            &:last-of-type {
                &::after {
                    border-color: $trans;
                    margin: 0;
                }
            }
            &.active span {
                display: inline-block;
                border-bottom: $tab-horizontal-text-decoration-hover;
            }
            &:hover span {
                color: $tab-horizontal-color-hover;
                border-bottom: $tab-horizontal-text-decoration-hover;
            }
            @include respond(large-up) {
                &:first-of-type {
                    padding-left: 0;
                }
            }
        }
    }
    .tab-container {
        .tabContent {
            padding: 25px;
            overflow: hidden;
        }
    }
}

// Secondary paragraph text (smaller)
//---------------------------------------

@mixin caret-down {
    position: relative;
    margin-top: rem(-1px);

    left: 44%;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        border-top: rem(13px) $border-style $input-border-color;
        border-left: rem(34px) $border-style transparent;
        border-right: rem(34px) $border-style transparent;
    }
    &::after {
        content: "";
        position: absolute;
        left: 2px;
        top: 0;
        border-top: rem(12px) $border-style white;
        border-left: rem(32px) $border-style transparent;
        border-right: rem(32px) $border-style transparent;
    }
}

@mixin sale-tag {
    @include h7();
    color: $badge-sale-color;
}

.promotions {
    .title.callout {
        @include sale-tag();
        font-size: 12px;
        font-weight: 600;
        @include respond(small-down) {
            font-size: 13px;
            text-align: center !important;
        }
    }
}

p.error,
span.error:not(.balance-error),
div.error:not(.coupon-error):not(.giftcert-error):not(.bucks-error) {
    @include info;
    color: $color-error;
    background: none;
    clear: both;
    &::before {
        content: url("../../images/svg-icons/icon_error.svg");
        display: inline-block;
        margin-right: rem($error-margin-right);
        width: rem($icon-error-width-mobile);
        height: rem($icon-error-width-mobile);
        position: relative;
        top: rem($error-top-mobile);
    }
    @include respond(large-up) {
        &::before {
            width: rem($icon-error-width-desktop);
            height: rem($icon-error-width-desktop);
            position: relative;
            top: rem($error-top-desktop);
        }
    }
    input {
        border-color: $input-border-color-error;
    }
    .leftindent {
        margin-left: 0;
        @include respond(large-up) {
            margin-left: rem(26px);
        }
    }
}