// sass-lint:disable-all

// Screen is 599px or smaller
@mixin mobile-only {
    @media only screen and (max-width: 599px) { @content; }
}

// Screen is 899px or smaller
@mixin for-tablet-and-below {
    @media only screen and (max-width: 899px) { @content; }
}
// Screen is 767px or smaller
@mixin for-tablet-and-mobile {
    @media only screen and (max-width: 767px) { @content; }
}
// Screen is at least 600px
@mixin for-tablet {
    @media only screen and (min-width: 600px) { @content; }
}

  // Screen is at least 900px
@mixin for-desktop {
    @media only screen and (min-width: 900px) { @content; }
}
// Screen is at least 1079px
@mixin for-desktop-upto-1079 {
    @media only screen and (min-width: 1080) { @content; }
}
  // Screen is at least 1250px
@mixin for-jumbo-desktop {
    @media only screen and (min-width: 1250px) { @content; }
}

// sass-lint:disable-all

// Screen is 599px or smaller
@mixin mobile-only {
    @media only screen and (max-width: 599px) {
        @content;
    }
}
// Screen is 375px or smaller
@mixin mobile-small {
    @media only screen and (max-width: 375px) {
        @content;
    }
}
// Screen is below 1250px
@mixin for-below-jumbo-desktop {
    @media only screen and (max-width: 1249px) {
        @content;
    }
}
// Screen is 960px or smaller
@mixin for-tablet-and-below {
    @media only screen and (max-width: 960px) {
        @content;
    }
}

// Screen is 768px or smaller
@mixin for-tablet-and-mobile {
    @media only screen and (max-width: 768px) {
        @content;
    }
}
@mixin for-large-tablet {
    @media only screen and (min-width: 768px) and (max-width: 960px) {
        @content;
    }
}

// Screen is at least 600px
@mixin for-tablet {
    @media only screen and (min-width: 600px) {
        @content;
    }
}

@mixin tablet-only {
    @media only screen and (min-width: 600px) and (max-width: 960px) {
        @content;
    }
}

// Screen is at least 961px
@mixin for-desktop {
    @media only screen and (min-width: 961px) {
        @content;
    }
}

// Screen is at least 1250px
@mixin for-jumbo-desktop {
    @media only screen and (min-width: 1250px) {
        @content;
    }
}

@mixin for-xjumbo-desktop {
    @media only screen and (min-width: 1500px) {
        @content;
    }
}

@mixin ie11 {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

// visibility
@include tablet-only {
    .hide-tablet {
        display: none !important;
    }
    .show-tablet {
        display: inherit !important;
    }
}

@include mobile-only {
    .hide-mobile {
        display: none !important;
    }
    .show-mobile {
        display: inherit !important;
    }
}

.show-tablet {
    display: none;
}

.show-mobile {
    display: none;
}

/*
    These styles are needed for a full bleed presentation
    and override existing Gymboree site styles.
*/

#wrapper.full-bleed {

    #main {
        width: 100%;
        max-width: none;
        padding-left: 0;
        padding-right: 0;
        @include for-tablet-and-below {
            margin: 0 !important;
        }
    }

    .container {
        max-width: 1920px;
    }

    .breadcrumb {
        display: none;
    }

    .primary-content {
        width: 100%;
        padding-left: 0;
    }
}

// Layette specific vars
$layette-blue: #19365E;
$layette-darkblue: #071d36;
$layette-black: #3A3A3A;
$layette-black2: #6D6853;
$layette-lightgray: #F3F3F3;
$layette-offwhite: #D5D5D5;
$layette-gray: #A5A5A5;
$layette-darkgray: #636669;

// Layette base component styles - use the matching mixin (if exists)
%layette-h1-base {
    font-family: $font-heading4;
    font-weight: 300;
    letter-spacing: 0.25em; // 10px
    line-height: 1.45em; // 58px

    text-transform: uppercase;

    color: $layette-blue;
}

%layette-h2-base {
    font-family: $font-heading4;
    font-weight: 300;
    letter-spacing: 0.214444444444444em; // 3.86px;
    line-height: 1.555555555555556em; // 28px;

    text-transform: uppercase;

    color: $layette-blue;
}

%layette-subhead-base {
    font-family: $font-heading4;
    font-weight: 300;
    letter-spacing: 0.214285714285714em; // 3px
    line-height: 1.857142857142857em; //24px

    text-transform: uppercase;

    color: $layette-black;
}

%layette-p-base {// 16px
    font-family: $font-body;
    letter-spacing: 0.035714285714286em; // 0.5px;
    line-height: 1.5em; // 26px;

    text-transform: none;

    color: $layette-black;
}

%layette-hr {
    border: none;
    width: 100%;
    height: 2px;
    display: block;

    background-color: $layette-blue;
}

%layette-decal-base {
    display: block;
    font-family: $font-heading2;
    color: $layette-black2;
    line-height: 1em;
    text-transform: lowercase;
}

%layette-nav-link {
    color: $layette-darkgray;
    font-family: $font-body;
    font-weight: 800;
    letter-spacing: 0.076363636363636em; // 0.84px;
    line-height: 1.909090909090909em; // 21px;
    text-transform: uppercase;
}

%layette-responsive-img {
    width: 100%;
    max-width: 100%;
    height: auto;
}

%layette-button-base {
    font-weight: 900;
    letter-spacing: 0.206428571428571em; // 2.89px;
    padding: 1.225em 3.1em 1.315em;
    line-height: 1.35em;

    &.disabled {
        pointer-events: none;
        color: $layette-darkgray!important;
    }

    &.loading {
        position: relative;
        pointer-events: none;
        color: transparent !important;
        opacity: 0.85;
        transition: 0.55s opacity $lay-ease-out,
                    0.55s filter $lay-ease-out;

        &::after {
            content: "Loading...";
            display: inline;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%);
            color: #ffffff;
            font-size: 1em;
            line-height: 1.35em;
            padding: 1.225em 3.1em 1.315em;
        }
    }

    &.reverse-theme {
        background: #fff;
        color: $layette-blue;

        &:hover {
            background: $layette-darkblue;
            color: #fff;
        }
    }

    &.out {
        transition: 0.45s all $lay-ease-in;
        filter: blur(14px);
        opacity: 0.01;
    }

    &.hide {
        display: none;
    }
}

// Component Mixins: use these instead of the extends, if the mixin exists
@mixin layette-h1 {
    @extend %layette-h1-base;
    font-size: 24px;
    letter-spacing: 0.366666666666667em; // 8.8px
    line-height: 1.416666666666667em; // 34px

    @include for-jumbo-desktop {
        font-size: 28px;
    }
    /*@include for-jumbo-desktop {
        font-size: 30px;
        letter-spacing: 0.25em; // 13.2px @ 40px
        line-height: 1.45em; // 58px @ 24px
    }*/
    @include for-xjumbo-desktop {
        font-size: 40px;
        letter-spacing: 0.25em; // 13.2px @ 40px
        line-height: 1.45em; // 58px @ 24px
    }
}

@mixin layette-h1-alt {
    @extend %layette-h1-base;
    font-size: 24px;
    letter-spacing: 0.25em; // 6px;
    line-height: 1.416666666666667em; // 34px

    @include tablet-only {
        font-size: 28px;
        line-height: 2.071428571428571em; // 58px;
    }
   /* @include for-desktop {
        font-size: 26px;
        line-height: 1.611111111111111em; // 58px;
    }*/
    @include for-jumbo-desktop {
        font-size: 36px;
        line-height: 1.611111111111111em; // 58px;
    }
}

@mixin layette-h2 {
    @extend %layette-h2-base;
    font-size: 16px;
    @include for-tablet {
        font-size: 18px;
    }
}

@mixin layette-subhead {
    @extend %layette-subhead-base;
    font-size: 12px;
    @include for-jumbo-desktop {
        font-size: 14px;
    }
}

@mixin layette-p {
    @extend %layette-p-base;
    font-size: 14px;
    @include for-tablet {
        font-size: 16px;
    }
}

@mixin layette-decal {
    @extend %layette-decal-base;
    font-size: 22px;
    @include for-tablet {
        font-size: 24px;
    }
    @include for-desktop {
        font-size: 21px;
    }
    @include for-jumbo-desktop {
        font-size: 28px;
    }
}

// Global Layette
.layette-builder {
    .svg-library {
        display: none;
    }
}


/*
    These styles are needed for a full bleed presentation
    and override existing Gymboree site styles.
*/


.denim_guide {
    // .header-banner {
    //     display: none !important;
    // }

    #main {
        width: 100%;
        max-width: 1920px;
        padding-left: 0;
        padding-right: 0;
    }
	/*
    #rotating-banner-wrapper {
        height: 0;
    }
    */

    .breadcrumb {
        display: none;
    }

    .primary-content {
        width: 100%;
        padding-left: 0;
    }
}

.dg_wrapper {
    overflow: hidden;
    margin: 0 auto;
    text-align: center;
    width: 100%;
}
.page-content .primary-content {
    &.fit-guide-page {
        width: 100%;
        padding-left: 0;
        .dg_guide_wrapper {
            .dg_back_button.top {
                display: none;
            }
            .dg_guide_footer {
                .dg_back_button.bottom {
                    display: none;
                }
            }
        }
    }
}
