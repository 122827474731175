#wrapper {
    background-color: $white;
    border: 0;
    left: 0;
    margin: auto;
    position: relative;
    width: 100%;
    min-width: $small-breakpoint;
    &.menu-active {
        left: $mobile-menu-width;
        // safari positioning fix
        #primary-header.fixed {
            left: $mobile-menu-width;
        }
    }
    @include respond($mobile-menu-breakpoint) {
        &.menu-active {
            left: auto;
        }
    }
}

#maincontent {
    @include content-width();
    position: relative;
    @include respond($large-breakpoint, $max-content-breakpoint) {
        padding: 0 rem(10px);
    }

    .search-results {
        .breadcrumb {
            @include media-breakpoint-up(md) {
                margin-top: 0;
                padding-left: rem(12px);
                margin-bottom: 5px;
            }
        }
    }

    .breadcrumb {
        background: transparent;
        padding: 0;
        margin: rem(0, 0, 16px);

        @include media-breakpoint-down(md) {
            margin-top: rem(10px);
            font-size: rem(13px);
        }

        a {
            &:hover {
                text-decoration: underline !important;
            }
        }

        .breadcrumb-item {
            color: $dark-grey;
            font-size: rem(16px);
            @include media-breakpoint-down(sm) {
                font-size: .875rem;
            }

            a {
                @include media-breakpoint-down(sm) {
                    &.home {
                        text-indent: -999em;
                        display: inline-block;
                        background-image: $icon-home;
                        background-size: contain;
                        background-repeat: no-repeat;
                        width: rem(13px);
                        height: rem(13px);
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
    }
}

.refinement-header.mobile-only {
    margin-top: 0;
}

.primary-content {
    width: 100%;
    @include respond(large-up) {
        box-sizing: border-box;
        float: right;
        margin-left: -1px; //negative #secondary border width
        position: relative;
        width: $primary-content-width;
        .primary-focus & {
            border: 0 none;
            float: left;
            margin: 0;
            width: 76%;
        }
        .full-width & {
            border: 0 none;
            width: 100%;
        }
        .page-content & {
            padding-left: 3%;
        }
    }
    &.search-results-no-sidebar {
        width: 100%;
    }
}

#secondary {
    border: 0;
    float: none;
    width: 100%;
    min-height: 50px;
    @include respond(large-up) {
        float: left;
        //width: $left-nav-width;
        height: auto;
        min-height: 50px;
        width: 25%;
        //position: relative;
        nav {
            padding-left: 10px;
            padding-right: 10px;
            a {
                display: block;
            }
        }
        .primary-focus & {
            background: none;
            border: none;
            box-sizing: border-box;
            float: left;
            padding-left: .4rem;
            width: 24%;
        }
    }
    .skip-to-primary {
        position: absolute;
        left: 0;
        top: -100px;
        background: #fff;
        font-size: 12px;
        padding: 0.25rem 1rem;
        opacity: 0;
        text-decoration: none;
        height: 1px;
        span {
            padding: 0 1.875rem;
            background: #1b375d;
            color: #fff;
            font-size: 0.875rem;
            line-height: 2.8125rem;
            letter-spacing: 0.025em;
            text-transform: uppercase;
        }
        &:focus {
            opacity: 1;
            top: -2px;
            left: 0;
            background: #fff;
            font-size: 12px;
            padding: 0.25rem 1rem;
            opacity: 1;
            text-decoration: none;
            position: absolute;
            bottom: 110%;
            width: 100%;
            height: 6em;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            color: #fff;
            background-color: hsla(0, 0%, 100%, .75);
            z-index: 999;
        }
    }
    h2.refinement-header {
        @include respond(small-down) {
            padding: 0 rem(10px);
            .content-asset {
                float: left;
                width: 90%;
                box-sizing: border-box;
            }
            .Arrow_small {
                float: left;
                margin-left: rem(20px);
            }
        }
    }
}

.secondary-content {
    clear: both;
}
