@import "~base/utilities/swatch";

$swatch-colors: (
    'beige': #f5f5dc,
    'black': $black,
    'blue': $blue,
    'brown': #a52a2a,
    'green': $green,
    'grey': #8f979d,
    'navy': #000080,
    'orange': #ffa500,
    'pink': #fe249a,
    'purple': #800080,
    'red': #f00,
    'white': $white,
    'yellow': #ff0,
    'silver': #C0C0C0,
    'khaki': #C7B59E,
    'gold': #AD8F5E,
);

@each $color in map-keys($swatch-colors) {
    $colorValue: #{map-get($swatch-colors, $color)};

    .swatch-circle-#{$color} {

        @include circle(2.5em, $colorValue);

        display: block;
        position: relative;

        &.disabled {
            opacity: 0.2;
        }
    }

    .swatch-filter-#{$color} {

        @include circle(1.38em, $colorValue);

        background-color: $colorValue;
        display: block;
        position: relative;

        &.disabled {
            opacity: 0.2;
        }
    }
}