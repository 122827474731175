#main {
    .pt_storefront {
        //override content-width for full bleed
        @include override-content-width();
        padding: 0;
    }
}

.content-container>div {
    margin-bottom: rem(20px);

    @include respond(large-up) {
        margin-bottom: rem(30px);
    }

    &::after {
        content: "";
        display: inherit;
        clear: both;
    }
}

.hp-new-arrivals-mobile {
    padding: 0 rem(7.5px);
    text-align: center;

    .h2 {
        margin-bottom: rem(15px);
    }

    .stroke-button {
        width: calc(50% - 5px);
        float: left;
        margin: 0 rem(2.5px) rem(10px);
    }
}

.hp-buckets-1>div,
.hp-buckets-2>div {
    max-width: $max-content-breakpoint;
    margin: 0 auto rem(30px);
}

.hp-buckets-2>div {
    @include respond(small-down) {
        margin-bottom: 0;
    }
}

// Get the look asset + product slider slot
.hp-asset-product-3 {
    .slot-banner {
        margin-bottom: rem(15px);
    }

    .slot-banner img {
        width: 100vw;
    }

    .asset-product-header {
        margin-bottom: rem(10px); // 20px total

        @include respond(large-up) {
            margin-bottom: 0;
        }
    }

    .carousel-header {
        margin-bottom: rem(35px); // 40 total

        @include respond(large-up) {
            margin-bottom: rem(45px);
        }
    }

    .horizontal-carousel {
        margin-top: 0;
    }

    .slider-prev,
    .slider-next {
        @include respond(desktop) {
            display: none;
        }
    }

    @include respond(large-down) {
        .slider-prev {
            left: -2px;
        }

        .slider-next {
            right: -2px;
        }
    }
}

.hp-reco-banner-5 {
    margin-bottom: rem(25px);
    @include content-width;

    .carousel-header {
        margin-top: rem(5px); // 25 total
        margin-bottom: rem(15px); // 20 total

        @include respond(large-up) {
            margin-top: rem(40px); // 50 total
            margin-bottom: rem(25px); // 30 total
        }
    }

    .slider-prev {
        left: -2px;
    }

    .slider-next {
        right: -2px;
    }
}

.hp-outfits-7>div {
    // diagonal textured background
    background: repeating-linear-gradient(-45deg, $color-bg-light, $color-bg-light 2px, $color-bg-medium 4px, $color-bg-medium 4px);
    width: 100%;
    text-align: center;
    padding: rem(20px) 0;

    @include respond(large-up) {
        padding: rem(50px) 0;
    }

    .slot-banner {
        a {
            text-decoration: none;
        }

        img {
            @include respond(small-down) {
                max-width: calc(100% - 20px);
            }
        }
    }

    .tiles-container {
        display: none;

        &.slick-initialized {
            display: block;
        }
    }

    .grid-tile {
        @include respond(small-down) {
            max-width: rem(250px);
        }
    }

    .badge-flag,
    .product-name {
        display: none;
    }

    .collections-link {
        display: inline-block;
        margin-top: rem(10px);

        @include respond(large-up) {
            margin-top: rem(15px);
        }
    }

    .slider-prev {
        left: -15px; // overlapping tile

        @include respond(small-down) {
            display: none;
        }
    }

    .slider-next {
        right: -15px; // overlapping tile

        @include respond(small-down) {
            display: none;
        }
    }
}

.hp-social {
    background: $color-bg-light;
    padding: rem(15px) rem(10px) rem(25px);
    text-align: center;

    @include respond(large-up) {
        padding: rem(30px) 0;
    }

    .hp-social-content-wrapper {
        max-width: $max-content-breakpoint;
        margin: 0 auto rem(30px);
    }

    .bv-social-heading {
        margin-bottom: rem(10px);

        @include respond(large-up) {
            margin-bottom: rem(20px);
        }
    }

    // using this container to clip the extra SVG bounding box
    .svg-container {
        overflow: hidden;
        width: rem(48px);
        height: rem(25px);
        margin: 0 auto rem(10px);

        @include respond(large-up) {
            width: rem(65px);
            height: rem(34px);
            margin: 0 auto rem(10px); // 17px bottom margin total
        }
    }

    // svg has large bounding box, positioned relatively for proper placement
    .Sun1 {
        position: relative;
        top: -9px;
        left: -41px;
        height: 47px;

        @include respond(large-up) {
            height: 63px;
            top: -11px;
            left: -33px;
        }
    }

    p {
        margin-top: 0;
        margin-bottom: rem(10px);

        @include respond(large-up) {
            margin-top: rem(5px);
            margin-bottom: 0;
        }
    }

    .hashtag {
        color: $icon-color-light;
    }
}

.hp-seo>div {
    padding: 0 rem(20px) rem(20px);
    margin-top: rem(20px);
    text-align: center;

    @include respond(large-up) {
        @include content-width;
        margin-top: rem(25px);
        padding: 0 7% rem(30px);
    }
}

.floating-box {
    display: none;

    @include respond(large-up) {
        display: block;
        opacity: 0;
        visibility: hidden;
        position: fixed;
        right: 0;
        bottom: 0;
        float: right;
        padding: rem($floatingBox-padding);
        width: rem($floatingBox-width);
        height: rem($floatingBox-height);
        background: rgba(255, 255, 255, 0.95);
        box-shadow: $floatingBox-shadow;
        box-sizing: border-box;
        z-index: 101;
        @include font-size($floatingBox-font-size);
        text-align: center;
        transition: all .3s;

        &.active {
            visibility: visible;
            opacity: 1;
        }

        a {
            text-decoration: none;
        }

        .close {
            position: absolute;
            display: block;
            left: rem($floatingBox-close);
            top: rem($floatingBox-close);
            @include line-height($floatingBox-close);
            width: rem($floatingBox-close);
            height: rem($floatingBox-close);

            .icon {
                width: rem($floatingBox-close);
                height: rem($floatingBox-close);
            }
        }

        .title {
            @include promo4;
            color: $promo-color-dark;
            text-decoration: none;

            &::after {
                content: "";
                display: inline-block;
                margin-left: rem(3px);
                width: rem($floatingBox-icon);
                height: rem($floatingBox-icon);
                background: $input-select-arrow-up;
                background-size: contain;
                transform: rotate(90deg);
            }
        }

        p {
            margin: 0;
            padding: 0;
            @include font-size($floatingBox-font-size);
        }
    }
}

#email-signup-modal {
    display: none;
}

#popup-video-modal {
    display: none;
}

.email-modal {
    @include respond(small-down) {
        .ui-dialog-content {
            padding: 1% 10% 10%; //override global in small
        }
    }

    h2 {
        margin-bottom: rem(10px);
    }

    #email-modal-address {
        width: 100%;
        float: left;
        margin-bottom: rem(5px);

        @include respond(large-up) {
            width: calc(70% - 10px);
            margin-right: rem(10px);
        }

        @include respond(desktop) {
            width: calc(75% - 10px);
        }
    }

    #email-modal-address-error {
        float: left;
        text-align: left;
        width: 100%;
        margin-bottom: rem(10px);

        @include respond(large-up) {
            width: auto;
        }
    }

    button {
        @include respond(small-down) {
            margin-top: rem(5px);
        }
    }

    p.info {
        margin-top: rem(20px);
    }
}

#email-response-modal {
    text-align: left;
    margin-left: rem(18px);

    @include respond(large-up) {
        margin-left: rem(25px);
    }
}

.country-modal {
    .ui-dialog-titlebar.ui-widget-header {
        display: none;
    }

    #dialog-container.ui-dialog-content {
        padding: 0% 10%;

        .view_popup {
            cursor: pointer;
        }

        #azadeaRedirect {
            font-family: "Avenir Medium";
            font-size: 18pt;
            color: #63666a;
            letter-spacing: .025em;
            padding-bottom: 30px;
            padding-right: 20px;

            @include respond(small-down) {
                padding-right: 1px;
            }

            .desk_mode_top {
                display: block;

                @include respond(small-down) {
                    display: none;
                }
            }

            .desk_mode_gym {
                display: block;

                @include respond(small-down) {
                    display: none;
                }
            }

            .mobile_mode_gym {
                display: none;

                @include respond(small-down) {
                    display: block;
                }
            }

            .mobile_mode_top {
                font-family: "Avenir Medium";
                font-size: 10pt;
                color: #63666a;
                letter-spacing: .025em;
                display: none;

                @include respond(small-down) {
                    display: block;
                }
            }

            .desk_mode_bottom {
                display: block;

                @include respond(small-down) {
                    display: none;
                }
            }

            .mobile_mode_bottom {
                font-family: "Avenir Medium";
                font-size: 13pt;
                color: #63666a;
                letter-spacing: .025em;
                display: none;

                @include respond(small-down) {
                    display: block;
                }
            }

            .desk_mode_image {
                display: block;
                padding-right: 20px;

                @include respond(small-down) {
                    display: none;
                }
            }

            .mobile_mode_image {
                font-family: "Avenir Medium";
                font-size: 12pt;
                color: #63666a;
                letter-spacing: .025em;
                display: none;

                @include respond(small-down) {
                    display: block;
                }
            }
        }
    }
}


    .product-horizontal-carousel .carousel-inner {
        display: flex;
    }

    .product-horizontal-carousel .carousel-item {
        margin-right: 0;
        flex: 0 0 33.333333%;
        display: block;
    }
