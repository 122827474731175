.content-slot img {
    height: auto;
    max-width: 100%;
}

.slot-grid-header p {
    margin: 0;
    padding: 0;
}

.product-listing-1x4 {
    background: none repeat scroll 0 0 $white;
    overflow: hidden;
    padding: .83em 0;
    .search-result-items .grid-tile {
        margin: 0 1% 0 2%;
        width: 18%;
        @include respond(small-down) {
            margin: 2%;
            width: 92%;
        }
    }
}

.account-nav-asset {
    background: url("../images/interface/icon-double-line-header.png") repeat-x scroll left top transparent;
    line-height: 1.5em;
    margin: 3.33em .83em;
    padding: .83em 0;
    h2 {
        margin: 1.16em 0 1.66em;
    }
}

.content-asset {
    word-wrap: break-word; // requested by QA in case client adds long text
}

.browser-compatibility-alert {
    background-color: $red;
    color: $white;
    line-height: 2em;
    padding: 0.8em;
}

.menu-slot {
    display: none;
    margin-top: 0.5em;
    padding-top: 1.5em;
    .social-links {
        padding-left: 0;
        li {
            display: inline-block;
            padding-left: 0;
        }
    }
    @include respond(large-up) {
        width: 13.5rem;
        display: block;
    }
}

.cart-recommendations,
.category-slot,
.product-slot {
    h1 {
        color: $white;
        position: absolute;
        text-align: right;
        bottom: 2%;
        left: 2%;
    }
    h2 {
        margin: 0;
        padding: .1rem .5rem;
    }
    h3 {
        background-color: $white;
        bottom: 0;
        color: $black;
        margin: 0;
        padding: 2rem;
        position: absolute;
        width: 100%;
    }
    .catlanding-banner {
        overflow: hidden;
        position: relative;
        width: 100%;
        img {
            height: auto;
            width: 100%;
        }
    }
    .category-tile {
        float: left;
        overflow: hidden;
        padding: 0 .15%;
        position: relative;
        width: 100%;
        @include respond(large-up) {
            width: 33%;
        }

        img {
            height: auto;
            width: 100%;
        }
    }
    .cat-banner {
        overflow: hidden;
        position: relative;
        width: 100%;
        img {
            height: auto;
            width: 100%;
        }
        h1 {
            right: 2%;
        }
    }
}

@include respond(large) {
    .html-slot-container img {
        max-width: 100%;
    }
}

.product-recom-slider .tiles-container {
    display: none;
    &.slick-initialized {
        display: block;
    }
}
