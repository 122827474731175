//-----------------------------------------------
//  EXTENSIBLE CLASSES
//
//  Extending an existing class adds the current selector to the chain of selectors for that extended class.
//
//  Example SCSS:
//  .myclass {color: #cf762d;}
//  .newclass {@extend .myclass;}
//
//  Compiles to CSS:
//  .myclass, .newclass {color: #cf762d;}
//
//  Best practices when using the @extend directive in Sass:
//  Make sure the extended selector (.myclass in the example above) is present only once throughout the site.
//  Avoid extending from nested selectors. This could create more CSS bloat than just copy and pasting those extended styles themselves.
//  Avoid chaining @extend directives.  Unpredictable behavior could result.
//  Don’t try extending from within a media query; it doesn’t work.
//
//  Any of the following can apply directly in HTML as a class, or extend in SCSS using the @extend directive
//-----------------------------------------------


// Inner page content width
//---------------------------------------
.content-width {
    @include content-width()
}

// Clearfix
//---------------------------------------
.clearfix {
    @include clearfix();
}

// Visually Hidden Elements
//---------------------------------------
.visually-hidden {
    @include visually-hidden;
}

// Menu - Base Styles
//---------------------------------------
.menu {
    @include menu();
}

// Full-Bleed (using VW's)
//---------------------------------------
.full-bleed-width {
    @include full-bleed-width();
}

// viewport visiblity

// small and large: < 961
.mobile-only {
    @include respond(desktop) {
        display: none !important;
    }
}

// large and desktop: 768+
.large-up {
    @include respond(small-down) {
        display: none !important;
    }
}

// small only: < 769
.small-down {
    @include media-breakpoint-up(lg) {
        display: none !important;
    }
}

// desktop only 961+
.desktop-only {
    @include media-breakpoint-down(md) {
        display: none !important;
    }
}

// quick display none class
.hidden {
    display: none;
}
