#cart-modal {
    .add-to-bag-dialog {
        max-width: rem(1040px);
    }
    .modal-header {
        background-color: transparent;
        border-bottom: 0;

        .full-pdp-link {
            color: #{var(--skin-primary-color-1)};
        }

        .close {
            font-size: 2rem;
            line-height: 1.5rem;
            opacity: 1;
            z-index: 1;
        }

        .close-icon {
            background-image: url("../../images/svg-icons/x.svg");
            background-position: rem(-5px) rem(-5px);
            height: rem(15px);
            width: rem(15px);
            display: inline-block;
            @include media-breakpoint-up(lg) {
                height: rem(24px);
                width: rem(24px);
                background-position: 0 0;
            }
        }
    }
    .modal-body {
        padding: rem(16px, 24px);
        @include media-breakpoint-down(md) {
            margin-top: rem(-50px);
        }
    }
    .modal-content {
        border-radius: 0;
    }
    .add-to-cart-modal-top {
        @include media-breakpoint-down(md) {
            display: flex;
            justify-content: center;
            padding-bottom: rem(10px);
            .checkout-continue {
                max-width: rem(300px);
            }
        }

        .icon {
            width: rem(12px);
            height: rem(16px);
            margin-right: rem(5px);

            @include media-breakpoint-up(lg) {
                width: rem(20px);
                height: rem(20px);
            }
        }
        h3 {
            font-size: rem(14px);
            line-height: rem(17px);
            font-family: $font-heading;
            color: $navy;
            @include media-breakpoint-up(lg) {
                text-align: center;
                font-family: $font-heading;
                font-size: rem(18px);
                font-style: normal;
                font-weight: 850;
                line-height: 150%;
                letter-spacing: rem(4.32px);
                text-transform: uppercase;
            }
            @include media-breakpoint-down(lg) {
                letter-spacing: rem(3.4px);
            }
        }
        .added-bag {
            @include media-breakpoint-up(md) {
                border-right: 2px solid $navy;
            }
            padding-top: rem(2.5px);
            padding-bottom: rem(2.5px);
            padding: rem(2.5px, 24px);
            .check-icon {
                @include media-breakpoint-down(lg) {
                    width: rem(16px);
                    height: rem(16px);
                }
            }
        }
        .add-head {
            @include media-breakpoint-up(lg) {
                margin-top: -60px;
            }
        }
        .add-head {
            @include media-breakpoint-up(lg) {
                margin-top: rem(-50px);
            }
        }

    }
    .product-summary {
        @include media-breakpoint-up(lg) {
            margin-top: 40px;
        }
        .card {
            border: 0
        }
        .card-body {
            padding: 0;
        }
        .checkout-continue {
            > a {
                max-width: 100%;
                height: rem(54px);
                @include media-breakpoint-down(lg) {
                    margin-top: rem(18px);
                    margin-bottom: rem(28px);
                    letter-spacing: rem(2.5px);
                }
            }
        }
        @include media-breakpoint-up(lg) {
            .checkout-continue {
                > a {
                    text-align: center;
                    font-family: $font-heading;
                    font-size: rem(14px);
                    font-weight: 850;
                    line-height: 350%;
                    letter-spacing: rem(3px);
                    margin-bottom: rem(20px);
                    max-width: rem(320px);
                }
            }
        }
        .text-center {
            span, a.continue-shopping-link {
                color: $dark-grey !important;
                font-family: $font-heading  !important;
                font-size: rem(12px)  !important;
                font-weight: 850  !important;
                line-height: 150%  !important;
                letter-spacing: rem(0.64px) !important;
                text-transform: uppercase;
            }
            a.continue-shopping-link {
                letter-spacing: rem(1.9px) !important;
            }
            span {
                margin-right: rem(4px);
            }
        }
    }
    .line-item-name {
        text-overflow: unset;
        white-space: unset;
        width: 100%;
        line-height: rem(20px);
        margin-bottom: rem(15px);
        overflow: unset;
    }
    .line-item-total-price {
        margin-bottom: rem(15px);
    }
    .line-item-attributes,
    .line-item-quantity {
        font-size: rem(14px);
        margin: rem(16px, 0);
    }
    .item-image {
        width: rem(178px);
        height: auto;
        @include media-breakpoint-up(lg) {
            padding: 0;
        }
        .product-image {
            width: 100%;
            max-height: 100%;
        }
    }

    .item-attributes {
        padding: rem(0, 40px);
        .line-item-attributes {
            color: $dark-grey;
            font-family: $font-body;
            font-size: rem(14px); 
            font-weight: 400;
            line-height: 150%;
            letter-spacing: rem(1.12px);
            text-transform: capitalize;

            span {
                color: $dark-grey;
                font-family: $font-body;
                font-size: rem(12px);
                font-weight: 600;
                line-height: rem(20px);
                letter-spacing: rem(1.92px);
            }
            &.size {
                text-transform: uppercase !important;
            }
        }
        .seller-info {
            line-height: 1.125rem;
        }
    }

    .line-item-quantity {
        .line-item-total-text {
            color: $dark-grey;
            font-family: $font-body;
            font-size: rem(12px);
            font-weight: rem(600);
            line-height: rem(20px);
            letter-spacing: rem(1.92px);
        }
        .qty-card-quantity-count {
            color: $dark-grey;
            font-family: $font-body;
            font-size: rem(14px);
            font-weight: 400;
            line-height: 150%;
            letter-spacing: rem(1.12px);
            text-transform: capitalize;
        }
    }

    .line-item-total-price {

        .price span.value {
            color: $dark-grey;
            font-family: $font-promo;
            font-size: rem(16px);
            font-weight: 850;
            line-height: 150%;
            letter-spacing: rem(1.28px);
        }

        .price span.strike-price {
            color: $red;
            font-family: $font-promo;
            font-size: rem(16px);
            font-weight: 850;
            line-height: 150%;
            letter-spacing: rem(1.28px);
        }
    }

    .line-item-header .line-item-name a {
        color: $dark-grey;
        font-family: $font-body;
        font-size: rem(20px);
        font-weight: 400;
        line-height: 150%;
        letter-spacing: rem(1.6px);
    }
}

.sold-and-shipped-label {
    color: $dark-grey;
    font-family: $font-body;
    font-size: rem(12px);
    font-weight: 600;
    line-height: rem(20px);
    letter-spacing: rem(1.92px);
}

.free-shipping {
    text-transform: uppercase;
    font-size: rem(12px);
    font-weight: 600;
    letter-spacing: rem(1.92px);
    line-height: normal;
}

.tooltip-custom-class {
    width: rem(16px) !important;
    height: rem(16px) !important;
    fill: #333 !important;
}