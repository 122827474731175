@import "menu";

.header {
    @include media-breakpoint-down(md) {
        margin-bottom: 5px;
    }
}

.header-banner {
    font-family: "Sackers Heavy Gothic";
    .content {
        margin-right: 0;
        padding-top: rem(12px);
        padding-bottom: rem(12px);
    }
}

.user {
    font-size: rem(12px);
    line-height: rem(16px);
    font-weight: 700;
}

.brand {
    position: absolute;
    left: 50%;
    display: block;
    text-align: center;

    &.checkout-brand {
        position: relative;
        padding-bottom: rem(24px);
        left: 0;
        margin: 0 auto;
    }

    img {
        width: 100%;
    }

    .Logo.svg-Logo-dims {
        @include media-breakpoint-down(md) {
            width: rem(130px);
            height: rem(30px);
        }
    }

    @include media-breakpoint-up(lg) {
        width: rem(250px);
        margin-left: -10em;
        padding-top: 1.5em;
        height: rem(30px);
    }

    @include media-breakpoint-down(md) {
        width: 10em; /* 64/16 */
        margin-left: -5em;
        padding-top: 1.3em;
    }
}

.navbar-header {
    height: auto;
    .search {
        @include media-breakpoint-up(lg) {
            margin-top: rem(10px);
        }
    }
}

.main-menu {
    @include media-breakpoint-up(lg) {
        margin-top: rem(20px) !important;
    }
}

.navbar-header {
    @include media-breakpoint-up(md) {
        .minicart {
            margin-top: rem(6px);
        }
    }
    @include media-breakpoint-up(lg) {
        height: auto;
        &.brand {
            top: 50%;
            padding-top: rem(15px);
            width: rem(350px);
        }
        .user {
            margin-top: rem(17px);

            a {
                .user-message {
                    &::after {
                        content: "";
                        border-right: 1px solid #e1e1e1;
                        font-size: rem(12px);
                        margin-left: 0.45rem;
                        margin-right: 0.15rem;
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .minicart {
            margin-top: 0;
        }
    }

    @include media-breakpoint-only(md) {
        &.brand {
            padding-top: rem(17px);
        }
        .search {
            margin-top: rem(9px);
        }
    }

    @include media-breakpoint-down(md) {
        &.brand {
            padding-top: rem(15px);
        }
    }

    @include media-breakpoint-down(xs) {
        &.brand {
            padding-top: rem(10px);
        }
    }
}

.search-minicart {
    @include media-breakpoint-up(sm) {
        flex-direction: row-reverse;
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }
}

@include media-breakpoint-only(sm) {
    .header.container {
        width: 100%;
        max-width: 100%;
    }

    .navbar-header {
        .search {
            margin-top: rem(10px);
        }
    }
}

.navbar-header .user .popover {
    top: rem(35px);
    border-radius: 0;
    width: rem(115px);
}

.signed-user {
    padding: rem(16px, 0);
}

.tracking-text {
    span {
        &:hover {
            text-decoration: underline;
        }
    }
}
