@import "../utilities/swatch";
@import "productCommon";
@import "quickView";

.product {
    .product-tile {
        position: relative;
        .tile-body .price {
            font-size: 14px;
        }
    
        .wishlistTile i.fa-circle {
            text-shadow: none;
        }
    
        @include media-breakpoint-down(md) {
            min-height: 23.4375em;
        }
    
        @include media-breakpoint-down(sm) {
            min-height: 19.6875em;
        }
    
        @include media-breakpoint-down(xs) {
            min-height: 13.4375em;
        }
    
        border: 0;
        margin-bottom: 0;
    
        .tile-body {
            padding: 0;
            .color-swatches {
                display: none;
                min-height: 2.25em;
    
                .product-tile-color-label {
                    cursor: pointer;
                    font-size: 1em;
    
                    @include media-breakpoint-down(md) {
                        font-size: 0.9375em;
                    }
    
                    @include media-breakpoint-down(sm) {
                        font-size: 0.8125em;
                    }
                }
            }
    
            .price {
                margin-bottom: rem(4px);
                font-size: $plp-price-font-size;
                font-family: $plp-price-font-family;
                letter-spacing: rem(0.8px);

                span {
                    font-weight: 850 !important;
                    @include media-breakpoint-down(md) {
                        font-size: rem(10px);
                    }
                    &.sales, &.strike-through {
                        color: $dark-grey;
                    }
                }

                @include media-breakpoint-up(md) {
                    margin-bottom: rem(10px);
                    line-height: 150%;
                    letter-spacing: rem(1.12px);
                    .list {
                        color: #63666A;;
                    }
                }
    
                .tiered {
                    font-size: 0.875em;
    
                    .value {
                        font-weight: bold;
                    }
                }
            }
    
            .coming-soon-tile {
                text-align: center;
            }
    
            .pdp-link {
                line-height: 1.2;
    
                a {
                    font-size: 1em;
                    text-decoration: none;
    
                    @include media-breakpoint-down(md) {
                        font-size: 0.9375em;
                    }
    
                    @include media-breakpoint-down(sm) {
                        font-size: 0.8125em;
                    }
                }
            }
    
            .ratings {
                font-size: 0.9em;
            }
        }
        .badge-flag {
            position: absolute;
            z-index: 1;
            width: auto;
            span {
                padding-right: 5px;
            }
        }
    
        .image-container {
            position: relative;
            overflow: hidden;
            margin-bottom: 2.5rem;
            @include media-breakpoint-down(md) {
                padding-top: 0.75rem;
                margin-bottom: 0;
            }
    
            @include aspect-ratio(279 / 296);
    
            .quickview {
                position: absolute;
                bottom: 1rem;
                right: 1rem;
    
                i {
                    &.fa-expand {
                        color: rgba(0, 0, 0, 0.7);
                    }
    
                    &.fa-circle {
                        color: rgba(255, 255, 255, 0.7);
                        text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
                    }
                }
            }
    
            a {
                display: block;
                height: 100%;
                position: relative;
                overflow: hidden;
    
                .tile-image {
                    width: 100%;
                    position: absolute;
                    left: 0;
                    @include media-breakpoint-up(md) {
                        min-width: 12.5rem;
                    }
                }
            }
        }
    
        .swatches {
            a {
                text-decoration: none;
            }
        }
    
        .swatch-circle {
            // $white will be replaced by color image background via Javascript
            @include swatch(1.8em, $white);
        }
    }
}

.product-detail .product-recommendations .product .product-tile {
    .image-container a .tile-image {
        @include media-breakpoint-up(md) {
            min-width: unset !important;
        }
    }
    .product-tile-name {
        a.link {
            letter-spacing: 0.019rem !important;
            line-height: 1.375rem !important;
        }
    }
    .promotions .promotion-content {
        text-align: center;
        .title.callout {
            letter-spacing: normal !important;
        }
    }
    .tile-body {
        .price {
            font-size: 11px !important;
        }
    }
}

#chooseBonusProductModal {
    .modal-footer {
        .container {
            margin-left: 0;
            width: 100%;
            margin-right: 0;
        }
    }

    .select-cbp-container {
        margin-top: auto;
        margin-bottom: auto;
    }

    .product-name-wrapper {
        width: 100%;
    }

    .bonus-quantity,
    .bonus-option {
        margin-top: 0.938em;
    }

    .bonus-quantity-select {
        min-width: 5em;
    }

    .select-bonus-product {
        margin-top: 1em;
    }

    .selected-pid {
        border: 1px solid $grey3;

        .bonus-product-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .bonus-product-price {
        text-align: center;
        margin-top: 1em;
    }
}

.product-horizontal-carousel {
    .product {
        .product-tile {
            .product-tile-name {
                min-height: 46px;
                margin-top: 8px;
                .link {
                    font-size: 12px !important;
                    font-family: $font-heading !important;
                    text-transform: uppercase !important;
                }
            }
            .price {
                .strike-through.list {
                    display: inline !important;
                }
            }
        }
    }
}

.product-recommendations {
    .recommendations {
        .carousel-recommendations {
            .pdp-vertival-tiles-container {
                .product-horizontal-carousel {
                    .product {
                        .product-tile {
                            .product-tile-name {
                                text-align: center;
                            }
                            .price {
                                text-align: center;
                            }
                        }
                    }
                }
                .tile-body-footer {
                    display: none;
                }
            }
        }
    }
}
