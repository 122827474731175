body {
    background: none repeat scroll 0 0 $white;
    color: $color-primary;
    font-family: $font-body;
    font-size: $font-size-base;
    &:not(.no-crop) {
        overflow-x: hidden; //to prevent full-bleed-width assets from causing a horizontal scroll bar to appear in page
    }

    #edq-verification-suggestion-box .edq-global-intuitive-address-suggestion {
        padding: 0 !important;
        margin: 0 25px !important;
        color: $dark-grey !important;
        height: 45px !important;
        line-height: 45px !important;
        border-color: $dark-grey !important;

        &:hover {
            background: $white !important;
            color: $navy !important;
        }
    }

    #edq-verification-suggestion-box .edq-global-intuitive-address-suggestions {
        border-color: $dark-grey !important;
    }
    
    #edq-verification-suggestion-box .edq-global-intuitive-address-suggestion.selected {
        background: $white !important;
        color: $navy !important;
    }
}

img {
    max-width: 100vw;
}

a {
    &.button {
        text-decoration: none;
    }
}

// let links in paragraphs inherit line height
p a {
    line-height: inherit;
}

fieldset {
    border: 0 none;
    margin: 0;
    padding: 0;
}

table {
    width: 100%;
}

th {
    text-align: left;
}

td {
    vertical-align: top;
}

// needed for SVG links to work in IE
// click events consumed by use tag and do not bubble up
svg use {
    pointer-events: none;
}
