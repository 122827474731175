.header-banner {
    width: 100%;
    // display: none !important;
    overflow: hidden;
    border: 0;
    background: $navy;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: $border-width $border-style $input-border-color;
    .show-div {
        transition: opacity 0.5s linear;
        transform: translate(0);
        opacity: 1;
        height: rem(70px);
    }
    .hide-div {
        transform: translate(9999px);
        opacity: 0;
        height: 0;
    }
    .topasset-btn {
        padding-top: rem(22px);
        cursor: pointer;
        float: right;
        position: absolute;
        right: 0;
        top: 0;
        svg {
            width: $icon-top-asset-width;
            height: $icon-top-asset-height;
            transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            transform-origin: 50% 50%;
        }
    }
    &.close {
        height: rem(40px);
        .show-div {
            transform: translate(9999px);
            opacity: 0;
            height: 0;
        }
        .hide-div {
            padding-top: rem(15px);
            transition: opacity 0.5s linear;
            transform: translate(0);
            opacity: 1;
        }
        .topasset-btn {
            padding-top: rem(7px);
            svg {
                transform: rotate(90deg);
                -moz-transform: rotate(45deg);
                transform-origin: 50% 50%;
            }
        }
    }
}
.header-promo-wrapper {
    margin: 0 auto;
    position: relative;
    max-width: $max-content-breakpoint;
    .content-asset {
        width: calc(100% - 40px);
        float: left;
        top: 0;
        height: rem(40px);
        position: absolute;
        padding-top: rem(15px);
        box-sizing: border-box;
    }
    .text-center {
        text-align: center;
        padding: rem(10px);
    }
    .header-center .promo_img {
        .nav_promo_strip_shipping_icon {
            width: rem(32px);
            height: rem(36px);
        }
        .Footer_Refer_A_Friend {
            width: rem(41px);
            height: rem(33px);
        }
        .Footer_gift_cards {
            width: rem(54px);
            height: rem(34px);
        }
    }
}
.top-asset-banner {
    .header-promo-item {
        width: 31.5%;
        float: left;
        margin: rem(16px) 0;
        border-right: $border-width $border-style $input-border-color;
        height: rem(30px);
        text-align: center;
        &:last-child {
            border: 0;
        }
        @include respond(large-down) {
            width: 31%;
        }
    }
    .promo_img {
        float: left;
    }
    .promo_title {
        @include h6();
        text-align: left;
    }
    &.hide-div {
        .promo_title {
            text-align: center;
            @include line-height(13px);
            padding: 0;
        }
    }
    .promo_txt {
        margin: 0;
        padding: 0;
        text-align: left;
    }
    .promo_right {
        padding-left: rem(10px);
        float: left;
        text-align: left;
    }
    .header-center {
        display: inline-block;
        a:hover {
            text-decoration: underline;
        }
    }
}

