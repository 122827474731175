@charset "UTF-8";


@import "base/components/common";

header ~ #maincontent .container a:not(.btn-primary, .btn-outline-primary, .small-link) {
    @extend p;
    color: $color-primary;
    font-size: rem(14px);
    cursor: pointer;
    overflow: hidden;
    text-decoration: $link-base-text-decoration;
    transition: all 0.25s ease;
    &:hover {
        color: $link-base-hover-color !important;
        text-decoration: none;
    }
    &.disabled {
        pointer-events: none;
        color: $button-disabled-bg !important;
        &:hover {
            color: $button-disabled-bg !important;
            text-decoration: none;
        }
    }
}

.card-header-custom {
    font-size: 1.0rem;
    margin-bottom: 0;
}

select.custom-select {
    background: $white $select-dropdown right 0 center/2px 2px no-repeat;
    background-size: rem(30px);
    display: inline-block;
    border-color: $dark-grey;
    border-radius: 0;
    font-size: rem(14px);
    padding: 0.375rem 0.75rem;
    &.is-invalid {
      background: $white $select-dropdown right 0 center/2px 2px no-repeat !important;
      background-size: rem(30px) !important;
      display: inline-block;
      border-color: $dark-grey;
      border-radius: 0;
    }
}

/* Padding classes for all sides */
.p-0 { padding: 0; }
.p-1 { padding: 0.25rem; }
.p-2 { padding: 0.5rem; }
.p-3 { padding: 1rem; }
.p-4 { padding: 1.5rem; }
.p-5 { padding: 3rem; }

/* Padding classes for top */
.pt-0 { padding-top: 0; }
.pt-1 { padding-top: 0.25rem; }
.pt-2 { padding-top: 0.5rem; }
.pt-3 { padding-top: 1rem; }
.pt-4 { padding-top: 1.5rem; }
.pt-5 { padding-top: 3rem; }

/* Padding classes for right */
.pr-0 { padding-right: 0; }
.pr-1 { padding-right: 0.25rem; }
.pr-2 { padding-right: 0.5rem; }
.pr-3 { padding-right: 1rem; }
.pr-4 { padding-right: 1.5rem; }
.pr-5 { padding-right: 3rem; }

/* Padding classes for bottom */
.pb-0 { padding-bottom: 0; }
.pb-1 { padding-bottom: 0.25rem; }
.pb-2 { padding-bottom: 0.5rem; }
.pb-3 { padding-bottom: 1rem; }
.pb-4 { padding-bottom: 1.5rem; }
.pb-5 { padding-bottom: 3rem; }

/* Padding classes for left */
.pl-0 { padding-left: 0; }
.pl-1 { padding-left: 0.25rem; }
.pl-2 { padding-left: 0.5rem; }
.pl-3 { padding-left: 1rem; }
.pl-4 { padding-left: 1.5rem; }
.pl-5 { padding-left: 3rem; }

/* Padding classes for horizontal and vertical */
.px-0 { padding-right: 0; padding-left: 0; }
.px-1 { padding-right: 0.25rem; padding-left: 0.25rem; }
px-2 { padding-right: 0.5rem; padding-left: 0.5rem; }
px-3 { padding-right: 1rem; padding-left: 1rem; }
px-4 { padding-right: 1.5rem; padding-left: 1.5rem; }
px-5 { padding-right: 3rem; padding-left: 3rem; }

.py-0 { padding-top: 0; padding-bottom: 0; }
.py-1 { padding-top: 0.25rem; padding-bottom: 0.25rem; }
py-2 { padding-top: 0.5rem; padding-bottom: 0.5rem; }
py-3 { padding-top: 1rem; padding-bottom: 1rem; }
py-4 { padding-top: 1.5rem; padding-bottom: 1.5rem; }
py-5 { padding-top: 3rem; padding-bottom: 3rem; }

/* Margin utility classes */
.m-1 { margin: 0.25rem; }
.m-2 { margin: 0.5rem; }
.m-3 { margin: 1rem; }
.m-4 { margin: 1.5rem; }
.m-5 { margin: 3rem; }

.mt-1 { margin-top: 0.25rem; }
.mt-2 { margin-top: 0.5rem; }
.mt-3 { margin-top: 1rem; }
.mt-4 { margin-top: 1.5rem; }
.mt-5 { margin-top: 3rem; }

.mr-1 { margin-right: 0.25rem; }
.mr-2 { margin-right: 0.5rem; }
.mr-3 { margin-right: 1rem; }
.mr-4 { margin-right: 1.5rem; }
.mr-5 { margin-right: 3rem; }

.mb-1 { margin-bottom: 0.25rem; }
.mb-2 { margin-bottom: 0.5rem; }
.mb-3 { margin-bottom: 1rem; }
.mb-4 { margin-bottom: 1.5rem; }
.mb-5 { margin-bottom: 3rem; }

.ml-1 { margin-left: 0.25rem; }
.ml-2 { margin-left: 0.5rem; }
.ml-3 { margin-left: 1rem; }
.ml-4 { margin-left: 1.5rem; }
.ml-5 { margin-left: 3rem; }

/* Custom padding utility classes for all breakpoints */
.px-xs-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  
  .py-xs-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  
  @media (min-width: 576px) {
    /* Small devices (sm) and larger */
    .px-sm-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  
    .py-sm-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
  
  @media (min-width: 768px) {
    /* Medium devices (md) and larger */
    .px-md-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  
    .py-md-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
  
  @media (min-width: 992px) {
    /* Large devices (lg) and larger */
    .px-lg-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  
    .py-lg-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
  
  @media (min-width: 1200px) {
    /* Extra-large devices (xl) and larger */
    .px-xl-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  
    .py-xl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
  
.fw-600 {
  font-weight: 600 !important;
}

.color-primary {
    color: $navy !important;
}
a.btn.btn-primary:hover{
    text-decoration: none !important;
}

.bg-primary { background-color: $navy; }

.shipping-discount {
    color: $red;
}
.recommendations,
.custom-carousel {
    .slick-arrow {
        top: 50%;
        transform: scale(1.5) translateY(-50%);
    }
}
.text-decoration-underline {
    text-decoration: underline !important;
}

.ships-from-container {
  margin-top: 5px;

  .ships-from {
    font-size: rem(12px);
    color: $dark-grey;
    font-family: $pricing-in-usd-font;
    line-height: 20px;
    text-transform: uppercase;
    letter-spacing: rem(1.92px);
    font-weight: 600;
  }
}

.brand-info-text {
  color: $dark-grey;
  font-family: "Nunito Sans";
  font-size: rem(12px);
  line-height: rem(18px);
  letter-spacing: rem(0.96px);
}

.brand-info-text + .content-asset {
  display: inline;
  color: $dark-grey;
  font-family: "Nunito Sans";
  font-size: rem(12px);
  line-height: rem(18px);
  letter-spacing: rem(0.96px);
}

.brand-info-text.add-to-bag {
  font-family: $font-body;
}

.brand-info-text.add-to-bag + .content-asset {
  font-family: $font-body;
}

.third-party-not-available,
.third-party-not-available-quickView,
.third-party-not-available-wishList {
  color: $red;
  font-family: "Nunito Sans";
  font-size: rem(14px);
  line-height: rem(18px);
  letter-spacing: rem(0.96px);
  position: absolute
}

.third-party-not-available-wishList {
  margin-top: rem(50px);
  position: relative;
}

.third-party-not-available-quickView {
  display: none;
}

.visibility-visible {
  visibility: visible;
}

.quantity-wrapper {
  label {
      color: $dark-grey;
      font-family: "Nunito Sans";
      font-size: rem(12px);
      font-weight: 600;
      line-height: rem(20px);
      letter-spacing: rem(1.92px);
      text-transform: uppercase;
  }

  input {
      color: $dark-grey;
      font-family: "Nunito Sans";
      font-size: rem(16px);
      font-style: normal;
      font-weight: 400;
      line-height: rem(20px);
      letter-spacing: rem(2.56px);
  }

  button {
      color: $dark-grey;
  }

  .custom-quantity-selector {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.5px solid $dark-grey;
      height: rem(54px);
      padding: 0 rem(16px);
      width: rem(138px);
      &.disabled {
        background-color: $gray-2;
      }
      @include media-breakpoint-down(sm) {
        width: 100%;
    }
  
      button, input {
          border: 0;
      }
  
      button {
          flex-basis: 25%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: transparent;
          height: 100%;


          &.quantity-increase, &.quantity-widget-increase {
              background: url("../../images/svg-icons/plus.svg") center no-repeat;
          }

          &.quantity-decrease, &.quantity-widget-decrease {
              background: url("../../images/svg-icons/minus.svg") center no-repeat;
          }
      }
  
      input {
          flex-basis: 50%;
          text-align: center;
          outline: none;
          box-shadow: none;
      }

      input:focus {
          outline: none;
          box-shadow: none;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type=number] {
          -moz-appearance: textfield;
      }
  }

  .disabled-btn {
    opacity: 0.5;
    cursor: not-allowed;
  }

  div.quantity-error {
    color: $red;
  }
  div.quantity-warning {
    color: $dark-grey;
  }
  div.quantity-error, div.quantity-warning {
    font-family: "Nunito Sans";
    font-size: rem(12px);
    line-height: rem(18px);
    letter-spacing: rem(0.96px);
  }
}
