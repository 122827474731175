@import "base/components/footer";
$max-content-breakpoint: 1170px;

.chat-container {
    bottom: 0px;
    position: fixed;
    left: 0;
    height: 10vh;
}
.chat-frame {
    width: 100%;
    height:100%;
    border: none;
}
@media only screen and (max-width: 1000px) {
    .chat-container {
        bottom: rem(10px);
        position: fixed;
        left: 0;
        height: 10vh;
    }

    .copyright-notice {
        padding: 0;
    }
}

footer {
    padding-top: 0;
    padding-bottom: rem(40px);
    background-color: #efefef;
    @include media-breakpoint-up(md) {
        padding-bottom: rem(20px);
    }
    .copyright {
        font-size: rem(12px);
        text-align: center;
        a {
            text-decoration: none;
            @include line-height(24px);
            margin: 0;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .postscript {
        font-size: rem(12px);
        padding: rem(25px) 0;
        text-align: center;
        @media only screen and (max-width: 480px) {
            text-align: center !important;
        }
        
        clear: both;
        line-height: rem(19px);
        @include respond(small-down) {
            padding: rem(15px) 0 rem(25px) 0;
        }
    }

    h2.title {
        font-family: $font-body;
        font-size: rem(14px);
    }

    .content li {
        font-size: rem(12px);

        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .content {
            font-size: rem(12px);
        }

        .footer-container .footer-item.collapsible-xs {
            &.active {
                button.title {
                    color: $navy;
                }
            }
            button {
				font-family: $font-heading;
				font-size: rem(11px);
				color: $dark-grey;

				&:focus,
				&:hover {
					color: $navy;
				}
			}
		}
    }
}

footer .social-links {
    margin: 20px 0 0;
    a {
        margin-bottom: 0;
    }
    @include media-breakpoint-down(xs) {
        display: flex;
        justify-content: center;
        width: 100%;

        li {
            width: 50%;
            margin: 5px 10px;
        }
    }
}

.back-to-top {
    &.footer-back {
        position: static;
    }

    position: fixed;
    bottom: rem(25px);
    right: rem(23px);
    text-align: center;
    cursor: pointer;
    transition: all .3s;
    opacity: 0;
    visibility: hidden;
    z-index: 100;
    &.show {
        visibility: visible;
        opacity: 1;
        z-index: 100;
    }
    @include respond(small-down) {
        right: rem(15px);
        bottom: rem(10px);
    }
    svg {
        width: rem(58px);
        height: rem(58px);
        @include respond(small-down) {
            width: rem(30px);
            height: rem(30px);
        }
    }
}

.footer-container-promo {
    max-width: rem(1170px);
    margin: 30px auto 0;

    .promo_right {
        text-align: center;
    }
}

.footer-promo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .footer-promo-item {
        @include media-breakpoint-down(md) {
            width: 100%;
            border-bottom: 1px solid $disabled-grey;

            a {
                display: flex;
                justify-content: center;
                align-items: center;

                .promo_right {
                    margin-left: rem(25px);
                }
            }

            &:not(:last-child) {
                margin-bottom: rem(20px);
            }
        }
    }

    .promo_img {
        text-align: center;

        svg.icon {
            width: rem(84px);
            height: rem(84px);
        }
    }
}

.store-wrapper,
.socialicons-wrapper {
    display: none;
}

.email-alert-signup {
    display: flex;

    .input-text {
        width: rem(244px);
        height: rem(52px);
        padding-left: rem(20px);
        border: 1px solid $dark-grey;
        border-radius: 0;

        &::-webkit-input-placeholder {
            font-size: rem(13px);
            color: #63666a;
        }
        &:-moz-placeholder,
        &::-moz-placeholder {
            font-size: rem(13px);
            color: #63666a;
        }
        &:-ms-input-placeholder {
            font-size: rem(13px);
            color: #63666a;
        }

        @include media-breakpoint-down(sm) {
            font-family: Sackers Heavy Gothic,sans-serif;
            height: rem(45px);
            font-size: rem(13px);
        }
    }

    .secondary {
        width: auto;
        background: $navy;
        color: $white;
        text-transform: uppercase;
        font-size: 0.875rem;
        line-height: 1.25rem;
        letter-spacing: 0.025em;
        margin-left: -1px;
        border: 0;
        height: rem(52px);
        @include media-breakpoint-down(sm) {
            height: rem(45px);
        }
    }
}

.footer-container-social {
    margin: rem(20px, 0);
    padding-bottom: rem(20px);

    @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        margin-top: 0;
    }

    .modal-content {
        border-radius: 0;

        .close {
            opacity: 1;
            display: flex;
            justify-content: flex-end;
        }

        h2 {
            text-align: center;
            color: $navy;
            font-size: 1.125rem;
            line-height: 1.5rem;
            letter-spacing: .28em;
        }

        h3 {
            text-align: center;
        }
    }
}

.close {
    color: #000;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

#email-response {
    display: none;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);

    .modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 95%;
    }
}

@include media-breakpoint-down(sm) {
    .wcdContentLazyLoad p {
        min-height: 100% !important;
    }
}

.with-floatingBox .back-to-top {
    bottom: rem(190px);
}

.menu-footer li a {
    font-size: rem(12px);
}

#welcome-email-modal {
    .email-modal-wrapper {
        h6 {
            line-height: 16px;
        }
    }

    .modal-content {
        border-radius: 0;
    }

    .modal-dialog {
        max-width: 100%;
        width: 90%;
        margin: 0 auto;
        @media screen and (min-width: 768px) {
            max-width: rem(800px);
            width: 100%;
        }
    }
    .modal-header {
        border-bottom: 0;
    }
    .close {
        opacity: 1;
        display: flex;
        justify-content: flex-end;
        .Close {
            width: rem(15.5px);
            height: rem(14.5px);
            @media screen and (min-width: 768px) {
                width: rem(31px);
                height: rem(29px);
            }
        }
    }

    .modal-body {
        width: 100%;
        text-align: center;
        padding: 4px 37px 37px;

        @media screen and (min-width: 768px) {
            padding: 8px 120px 80px;
        }

        h1 {
            color: $navy;
            font-size: rem(18px);
            line-height: rem(21px);

            @media screen and (min-width: 768px) {
                font-size: rem(24px);
                line-height: rem(36px);
            }
        }
        
        #email-modal-signup {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 15px;
            @media screen and (min-width: 768px) {
                flex-wrap: nowrap;
                margin-bottom: 0;
            }

            .form-group {
                width: 100%;
                height: 45px;

                @media screen and (min-width: 768px) {
                    width: 74%;
                    height: 52px;
                    margin-right: 15px;
                }

                input {
                    padding: 0 2px 0 20px;
                    &::placeholder {
                        color: #63666a;
                    }
                }

                .invalid-feedback {
                    font-size: 12px;
                }
            }

            .secondary {
                background: #3c759a;
                color: #fff;
                width: 40%;
                margin: 20px auto 0;
                height: 45px;
                border: 0;
                text-transform: uppercase;
                @media screen and (min-width: 768px) {
                    width: 24%;
                    height: 52px;
                    margin: 0;
                }

                &:hover,
                &:focus {
                    background-color: $navy;
                }
            }
        }

        .info {
            margin-top: 40px;
            a {
                font-size: rem(12px) !important;
            }
        }
    }
}
.slick-initialized.slick-slider {
    .slick-arrow {
        min-height: auto !important;
    }
}
footer .copyright a {
    font-size: inherit;
}
