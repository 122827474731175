@import '../variables';

.product-tile .tile-body .price {
    font-size: 14px;
}

.price {
    color: $janie-black;
    font-family: $font-heading;

    .strike-price {
        color: $red !important;
    }

    .strike-through {
        text-decoration: line-through;
        color: $dark-grey;
        margin-right: rem(5px);
    }

    .starting,
    .range,
    .sales {
        font-weight: bold;
    }

    .tiered {
        color: $grey7;

        table {
            border-top: 1px solid $grey3;
            margin: 0 auto;

            tr {
                &:nth-child(odd) {
                    background-color: $grey2;
                }
            }
        }

        td,
        span.price {
            font-size: 0.875rem;
            font-weight: bold;
        }

        td {
            padding: 0.313rem;

            &.quantity {
                font-weight: normal;
                text-align: right;
            }

            &.value {
                text-align: left;
            }
        }

        .table-header {
            font-size: 1.125rem;
            padding: 0.313rem;
        }

        .column-header {
            font-size: 1rem;
            padding: 0.313rem;
            font-weight: normal;
        }

        .column-header.quantity {
            text-align: right;
        }
    }
}

// Wishlist
.wishlistItemCards {
    .wl-item-info {
        .price {
            font-size: 14px !important;
            font-style: normal;
            font-weight: 850;
            line-height: 150% !important;
            letter-spacing: 0.07em !important;
            font-family: $wishlist-price-font-family;
            color: $dark-grey !important;
            .strike-through {
                color: $dark-grey !important;
            }

            .sales {
                display: block
            }
        }
    }
}
