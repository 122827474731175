.form-group {
  position: relative;

  &.required {
    .form-control-label::before {
      content: '' !important;
    }
  }
  select.form-control {
    padding: 18px 5px 5px 17px;
    -webkit-padding-start: 17px;
    appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding-left: 20px;
    
    option {
      padding: 18px 5px 5px 17px;
      -webkit-padding-start: 17px;
    }
  }
  .form-control {
    width: 100%;
    height: 3.25rem;
    padding: 18px 5px 5px 20px;
    border: 1px solid #ccc;
    border-radius: 0;
    font-size: 14px;
    color: #63666a;
    &::placeholder {
      color: transparent;
    }

    &:focus + .form-control-label,
    &:not(:placeholder-shown) + .form-control-label {
      top: 6px;
      font-size: 12px;
    }
  }

  .form-control-label {
    position: absolute;
    top: 15px;
    left: 20px;
    pointer-events: none;
    transition: 0.3s ease;
    width: 100%;
    text-align: left;

    &:focus,
    &:valid {
      top: 6px;
      font-size: 12px;
    }
  }

  .invalid-feedback {
    position: relative;
    color: #c8102e;
    text-align: left;
    &:not(:empty)::before {
      content: url(../images/svg-icons/icon_error.svg);
      display: inline-block;
      margin-right: 0.375rem;
      width: 0.9375rem;
      height: 0.9375rem;
      position: relative;
      top: 0.1875rem;
    }
  }
}

.alert.alert-danger {
    position: relative;
    color: #c8102e;
    text-align: left;
    padding-left: 0;
    padding-bottom: 0;
    background: none;
    border: none;
    &::before {
      content: url(../images/svg-icons/icon_error.svg);
      display: inline-block;
      margin-right: 0.375rem;
      width: 0.9375rem;
      height: 0.9375rem;
      position: relative;
      top: 0.1875rem;
    }
}


.custom-checkbox {
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  label.custom-control-label {
    text-transform: uppercase;
    line-height: 2rem;
    width: 100%;
    span {
      position: absolute;
      top: 50%;
      transform: translateY(-20%);
    }
    @media screen and (max-width: 991px) {
      font-size: 13px;
    }
  }

  label.custom-control-label {
    &::before {
      content: '';
      position: relative;
      width: 1.875rem;
      height: 1.875rem;
      background-color: #fff;
      border: 2px solid #333;
      cursor: pointer;
      display: inline-block;
      border-radius: 0;
      border: 2px solid #000;
      background: none;
      margin-right: -10px;
      margin-bottom: -2px;
    }
    &::after {
      width: 1.875rem;
      height: 1.875rem;

      path {
        fill: #ffffff; /* Change to the desired color (white in this case) */
      }
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #1b375d;
    background: url("../images/svg-icons/Checkbox_Checked.svg") no-repeat;
    z-index: 1;
  }
  
  .custom-control-input.disabled:checked ~ .custom-control-label::before {
    background: url("../images/svg-icons/Checkbox_Disabled.svg") no-repeat;
    z-index: 1;
  }
  .custom-control-input.disabled:checked + .custom-control-label {
    pointer-events: none;
  }
}

input[type=checkbox],
input[type=radio] {
  opacity: 0;
  position: absolute;
}

input[type=checkbox]+label,
input[type=radio]+label {
  position: relative;
}

input[type=checkbox]+label span,
input[type=radio]+label span {
  display: block;
}

input[type=checkbox]+label:before,
input[type=radio]+label:before {
  content: "";
  background: #fff;
  border: 1px solid #071d36;
  box-sizing: border-box;
  display: inline-block;
  float: left;
  vertical-align: text-bottom;
  width: 1.875rem;
  height: 1.875rem;
  margin-right: 10px;
  text-align: center;
  margin-bottom: .9375rem;
}

@media only screen and (min-width: 768px) {
  input[type=checkbox]+label:before,
  input[type=radio]+label:before {
    width: 1.5625rem;
    height: 1.5625rem;
  }
}

.label-inline input[type=checkbox]+label:before,
.label-inline input[type=radio]+label:before {
  float: none;
}

input[type=checkbox]+label>span,
input[type=radio]+label>span {
  display: inline;
  position: relative;
  vertical-align: top;
  top: 5px;
  line-height: 1rem;

  @media only screen and (min-width: 768px) {
    top: 4px;
  }
}
input[type=radio]+label>span {
  top: 7px;
}
input[type=checkbox]:checked+label:before {
  background: url(../images/svg-icons/Checkbox_Checked.svg) no-repeat 50%;
}

.options-inline input[type=radio]+label:nth-of-type(n+2):before {
  margin-left: 2rem;
}

input[type=radio]+label:before {
  border-radius: 50%;
  float: none;
}

input[type=radio]:checked+label:before {
  background: url(../images/svg-icons/Radio_Selected.svg) no-repeat 50%;
  background-size: 30px;
  border-color: #071d36;

  @media only screen and (min-width: 768px) {
    background-size:25px;
  }
}

.checkbox.disabled {
  pointer-events: none;
}

.checkbox.disabled input[type=checkbox]:checked+label:before {
  background: url(../images/svg-icons/Checkbox_Disabled.svg) no-repeat 50%;
}

.form-control.search-field {
  padding-top: 5px;
}