@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.non-adjusted-price {
    display: inline-block;
}

.product-tile {
    .p-w-r {
        display: flex !important;
        justify-content: start;
    }
}

.pr-no-reviews {
    .pr-snippet-review-count {
        padding-left: 0 !important;
    }
    .pr-snippet-stars-container {
        display: none !important;
    }
}

.product-tile .pr-no-reviews {
    display: none !important;
}

.line-item-promo {
    color: $red;
    font-size: $red;
}

.page[data-action="Product-Show"] {
    #pr-reviewsnippet {
        display: none;
    }

    .p-w-r {
        margin-bottom: 18px;
    }

    .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-read-and-write,
    .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-stars-reco-reco,
    .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-read-and-write,
    .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-stars-reco-reco {
        margin-top: 5px !important;
        margin-left: 5px !important;

        .pr-snippet-write-review-link {
            text-decoration: underline !important;
            text-decoration-color: gray !important;
        }
    }

    .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-stars-container,
    .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-stars-container {
        @media screen and (max-width: 767.98px) {
            display: block;
            text-align: center;
            margin-top: 20px;
            display: flex !important;
            justify-content: center !important;
            width: 100% !important;
            margin-left: 0 !important;
        }
    }

    .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-read-and-write,
    .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-read-and-write {
        @media screen and (max-width: 767.98px) {
            display: block;
            text-align: center;
            display: flex !important;
            justify-content: center !important;
            width: 100% !important;
            margin-top: rem(10px);
        }
    }

    .p-w-r .pr-accessible-btn,
    .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets .pr-snippet-read-and-write a {
        color: $white !important;
    }

    .p-w-r .pr-accordion .pr-accordion-btn {
        text-transform: uppercase !important;
        font-family: $font-heading;
    }

    .p-w-r .pr-review-snapshot .pr-review-snapshot-header .pr-headline {
        color: $black !important;
    }

    .p-w-r .pr-rd-flag-review-btn{
        @include media-breakpoint-down(md) {
            @include fontsize(10px, 40px);
            width: rem(145px) !important;
        }
    }

    .p-w-r .pr-review-display .pr-rd-display-desktop .pr-rd-flag-review-container,
    .p-w-r .pr-helpful-btn,
    .p-w-r .pr-rd-flag-review-btn,
    .p-w-r .pr-btn-fileinput,
    .p-w-r .pr-accessible-btn,
    .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets .pr-snippet-read-and-write a,
    .p-w-r .pr-content-collection-form .pr-war .pr-clear-all-radios {
        background-color: $navy !important;
        border-color: $navy !important;
        text-transform: uppercase !important;
        cursor: pointer;
        letter-spacing: rem(0.26px);
        border-radius: rem(50px);
        font-family: $font-heading !important;
        display: inline-block;
        border: 0 !important;

        @include media-breakpoint-up(md) {
          @include fontsize(13px, 52px);
          height: rem(52px) !important;
          padding: rem(0, 30px) !important;
          width: auto !important;
        }
    
        &:disabled,
        &.disabled {
          cursor: not-allowed;
          pointer-events: none;
          opacity: 0.3;
        }
      
        &:focus {
          box-shadow: none;
        }
    
        &:not(:disabled),
        &:not(:disabled):not(:disabled) {
          &:hover,
          &:active {
            color: $white !important;
            background-color: $black !important;
            border-color: $black !important;
          }
        }
    }

    .p-w-r .pr-btn-fileinput,
    .p-w-r .pr-accessible-btn,
    .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets .pr-snippet-read-and-write a {
        font-size: 11px !important;
        height: 37.4px !important;
        line-height: 24px !important;
        padding: 0 10px !important;
    }

    .p-w-r .pr-submit a {
        font-size: 10px !important;
        line-height: 12px !important;
        text-decoration: underline !important;
        color: $navy !important;
    }

    .p-w-r .pr-thumbs-fill, .p-w-r .pr-thumbs-cuff-fill {
        fill: #fff !important;
    }

    .p-w-r .pr-helpful-count, .p-w-r .pr-rd-flag-review-btn{
        color: #fff !important;
    }

    .p-w-r .control-label span,
    .p-w-r .pr-control-label span,
    .p-w-r .pr-file-input-btn-group .pr-file-input-label {
        text-transform: uppercase !important;
    }

    .p-w-r .control-label span, .p-w-r .pr-control-label .pr-form-control-error-wrapper span {
        text-transform: capitalize !important;
    }

    .p-w-r .pr-read-review .pr-rd-main-header-with-filters .pr-rd-sort,
    .p-w-r .pr-review-display .pr-rd-main-header-with-filters .pr-rd-sort {
        height: 52px !important;

        @include media-breakpoint-down(md) {
            height: 45px !important;
        }
    }

    .p-w-r .pr-review-display .pr-rd-main-header-with-filters #pr-rd-sort-by {
        height: 52px !important;

        @include media-breakpoint-down(md) {
            height: 45px !important;
        }
    }

    .p-w-r .pr-read-review.pr-rd-display-desktop .pr-rd-main-header-with-filters .pr-rd-review-header-sorts .pr-rd-sort,
    .p-w-r .pr-read-review.pr-rd-display-tablet .pr-rd-main-header-with-filters .pr-rd-review-header-sorts .pr-rd-sort,
    .p-w-r .pr-review-display.pr-rd-display-desktop .pr-rd-main-header-with-filters .pr-rd-review-header-sorts .pr-rd-sort, 
    .p-w-r .pr-review-display.pr-rd-display-tablet .pr-rd-main-header-with-filters .pr-rd-review-header-sorts .pr-rd-sort {
        height: 52px !important;

        @include media-breakpoint-down(md) {
            height: 45px !important;
        }
    }

    .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-block-recommend {
        display: none !important;
    }
}
