@mixin set-button($col, $bg-color, $border-color, $important: false) {
    @if $important {
      color: $col !important;
      background-color: $bg-color !important;
      border-color: $border-color !important;
    }

    @else {
      color: $col;
      background-color: $bg-color;
      border-color: $border-color;
    }
}

// REM calculation
$html-font-size: 16px;

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number

@function strip-unit($number) {
@if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
}
@return $number;
}

@function rem($pxValue...) {
    $remval: ();

@each $val in $pxValue {
    $remval:append($remval, #{strip-unit($val) / strip-unit($html-font-size)}rem);
}

    @return $remval;
}

// EM calculation

@function em($pixels, $context: $html-font-size) {
    @return ($pixels / $context) * 1em;
}

// Mixin that will calculate and output a font-size/line-height pair

@mixin fontsize($size, $lineheight) {
    font-size: rem($size);
    line-height: em($lineheight, $size);
}

/* Mixin for inputField */

@mixin formInputField {
    width: 100%;
    color: $black;
    padding: rem(15px, 16px);
    box-shadow: none;
    height: rem(58px);
    font-size: rem(14px);
    font-family: $font-family-GothamBook;
    border: rem(1.5px) solid $black;
    border-radius: rem(0px);
    background-clip: initial;

    &::placeholder {
        color: $black;
    }

    &[type="text"] {
        -webkit-appearance: none;
    }

    &[type="search"] {
        -webkit-appearance: none;
    }

    &[type="number"] {
        -moz-appearance: textfield;
    }

    &:focus,
    &:hover {
        box-shadow: none;
    }

    &:disabled {
        border-color: $input-disabled-col;
        color: $input-disabled-col;
        background-color: $white;
    }
}

/* common mixin for checkbox and radio button */

@mixin checkboxRadioBtn {
    display: none;

    + label {
      cursor: pointer;
      position: relative;
      padding-left: rem(30px);
      @include fontsize(14px, 24px);
      font-family: $font-family-GothamBook;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: rem(2px);
        width: rem(20px);
        height: rem(20px);
        border: 1.5px solid $black;
      }
    }

    &:hover:not(:checked, :disabled) + label {
        &::before {
          background-color: $white-smoke;
        }
    }

    &:focus + label {
        outline: 1px solid $dove-gray;
    }

    &:disabled + label {
        &,
        &::before {
            cursor: default;
        }
        &::before {
            background-color: $mercury;
            border-color: $input-pr-bdr-col;
        }
    }
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    transition: $args;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

/**
    @mixin aspect-ratio
    Use CSS aspect-ratio rule where possible, but fall back to
    padding-top hack iff needed (i.e. iOS 14)
*/

@mixin aspect-ratio($ratio) {
    aspect-ratio: $ratio auto; // stylelint-disable-line

    @supports not (aspect-ratio: $ratio) {
        &::before {
            float: left;
            padding-top: percentage($ratio);
            content: '';
        }

        &::after {
            display: block;
            content: '';
            clear: both;
        }
    }
}

@mixin common-gift-btn {
    background-color: $navy;
    color: $white;
    border-color: $navy;
    text-transform: uppercase;
    cursor: pointer;
    letter-spacing: rem(0.26px);
    border-radius: 0;
    font-family: $font-heading;
    display: inline-block;

    @include fontsize(11px, 45px);
    height: rem(45px);
  
    @include media-breakpoint-up(md) {
      @include fontsize(13px, 52px);
      height: rem(52px);
      padding: rem(0, 30px);
    }

    &:disabled,
    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.3;
    }
  
    &:focus {
      box-shadow: none;
    }

    &:not(:disabled),
    &:not(:disabled):not(:disabled) {
      &:hover,
      &:active {
        color: $white;
        background-color: $black;
        border-color: $black;
      }
    }
}