/*
* All rotating-banner content assets are assigned to the promo-assets content folder
* The rotating-banner_custom_css.css file in the shared library is assigned to this folder
* The CSS on this page is back up for that file, with the exception of the display rules
* needed for the custom animation
*/

#rotating-banner-wrapper,
.rotating-banner-slot {
    display: block;
    position: relative;
    margin-top: 0;
    height: rem(28px);
    @include respond(large-up) {
        height: rem(48px);
    }
}
.rotating-banner-slot {
    display: none;
    &.slick-initialized {
        display: block;
    }
    &.slick-vertical {
        .slick-slide {
            height: rem(28px);
            @include respond(large-up) {
                height: rem(48px);
            }
        }
    }
}

.rotating-banner-slot > div {
    text-align: center;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: rem(28px);
    @include respond(large-up) {
        height: rem(48px);
    }
    .icon {
        margin-right: rem(10px);
        height: 25px;
        width: 31px;
        position: relative;
        top: -2px;
        &.Snowflake_Small {
            @include respond(small-down) {
                margin-right: 2px;
                width: 22px;
                height: 19px;
            }
        }
    }
    a {
        text-decoration: none;
        display: block;
    }
    span {
        color: $color-promo-text;
        @include line-height(30px);
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @include respond(large-up) {
        height: rem(48px);
        & > div {
            margin: 0 auto;
        }
        .icon {
            height: 33px;
            position: relative;
            top: -3px;
        }
        span {
            @include line-height(50px);
        }
    }
}
