@import "~base/miniCart";
@import "productCard";

.remove-btn {
    color: $slightly-darker-gray;
    float: right;
    background-color: white;
    border: none;
    font-size: 1.625em;
    margin-top: -0.313em;
    padding: 0;
}

.minicart {

    .popover {
        max-width: rem(392px);
        min-width: rem(392px);
        min-height: 14.7rem !important;
        border: 1px solid $light-grey;
        border-radius: 0;
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
        max-width: rem(198px);
        @include media-breakpoint-down(md) {
            display: none;
            &.show {
                display: none;
            }
        }
    }
    .cart {
        padding-top: rem(20px);
    }
    .line-item-name a {
        color: $dark-grey;
        font-family: $font-body;
        font-size: rem(12px);
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        letter-spacing: rem(0.96px);
        max-width: rem(198px);
    }

    .line-item-availability {
        .out-of-stock {
            color: $red;
        }
    }

    .product-summary {
        overflow-y: auto;
        max-height: rem(332px);
        overflow-x: hidden;
        padding-bottom: rem(16px);

        .product-line-item {
            .line-item-header {
                max-width: rem(250px);
            }
        }

        /* width */
        &::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: $light-grey;
            border-radius: rem(10px);
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $scroll-grey; 
            border-radius: rem(10px);
        }
    }

    .card {
        padding: 0 !important;
        border: 0 !important;
        margin-bottom: 0;
    }

    .card-body {
        padding: 0;
    }

    .checkout-continue {
        .mb-sm-3 {
            margin-bottom: 0 !important;
        }
        .checkout-btn {
            margin-bottom: 0;
            text-align: center;
            font-family: $font-heading;
            font-size: rem(14px);
            font-style: normal;
            font-weight: 850;
            line-height: rem(50px);
            letter-spacing: rem(0.8px);
        }
    }

    .price {
        color: $dark-grey;
        font-family: $font-body;
    }

    .minicart-footer {
        border-top: 0;
    }
    .mini-footer-br {
        border-top: 1px solid $medium-grey;
        margin: 0;
    }
    .line-item-attributes,
    .line-item-total-text,
    .line-item-pricing-info {
        font-size: rem(14px);
    }
    .line-item-attributes {
        margin-top: rem(2px);
    }
    .qty-price {
        margin-top: rem(2px);
    }
    .item-attributes {
        flex-grow: 1;
        .item-attributes-col {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            column-gap: rem(3px);
        }
        .price-col {
            display: block !important;
            .price-cart {
                .price {
                    display: flex;
                    flex-direction: column;
                    gap: rem(5px);
                    align-items: flex-end;
                    .strike-through {
                        margin-right: 0;
                    }
                }
            }
        }
        .qty-col {
            .price-cart{
                display: none;
            }
        }
    }
    .sub-total-label {
        color: $dark-grey;
        font-family: $font-body;
        font-size: rem(14px);
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: rem(1.12px);
    }
    .sub-total {
        color: $dark-grey;
        font-family: $font-heading;
        font-size: rem(14px);
        font-style: normal;
        font-weight: 850;
        line-height: 125%;
        letter-spacing: rem(0.28px);
    }

    .line-item-attributes, .sold-by  {
        color: $dark-grey;
        font-family: $font-body;
        font-size: rem(12px);
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        letter-spacing: rem(0.96px);
        text-transform: capitalize;

        .display-name {
            color: $dark-grey;
            font-family: $font-body;
            font-size: rem(10px);
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 15px */
            letter-spacing: rem(0.8px);
            text-transform: uppercase;
        }
    }
    .line-item-attributes {
        .display-name {
            margin-right: rem(3px);
        }
    }
    .line-item-pricing-info {
        .line-item-total-text  {
            color: $dark-grey;
            font-family: $font-body;
            font-size: rem(10px);
            font-style: normal;
            font-weight: 600;
            line-height: 150%; /* 15px */
            letter-spacing: rem(0.8px);
            text-transform: uppercase;
            margin-right: rem(3px);
        }
        .qty-card-quantity-count {
            color: $dark-grey;
            font-family: $font-body;
            font-size: rem(12px);
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 18px */
            letter-spacing: rem(0.96px);
            text-transform: capitalize;
        }
    }
    .price-cart {
        .line-item-total-price {
            .line-item-total-price-amount, .strike-through {
                color: $dark-grey;
                font-family: $font-heading;
                font-size: rem(12px);
                font-style: normal;
                font-weight: 850;
                line-height: 150%; /* 18px */
                letter-spacing: rem(0.96px);
            }
        }
    }
}

.modal-footer {
    button {
        width: 30%;
    }
}

.sold-shipped-by-minicart {
    color: $dark-grey;
    font-family: $font-body;
    font-size: rem(12px);
    font-weight: 400;
    line-height: 150%;
    letter-spacing: rem(0.96px);
    text-transform: capitalize;
}

.minicart-header {
    color: $navy;
    text-align: center;
    font-family: $font-heading;
    font-size: rem(18px);
    font-style: normal;
    font-weight: 850;
    line-height: 150%;
    letter-spacing: rem(4.32px);
    text-transform: uppercase;
}

.wishlist-out-of-stock {
    color: $red;
}


