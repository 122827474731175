.slick-slide {
    img {
        max-width: 100%;
    }
    .tooltip {
        display: block; //overrides display none on SG tooltips
    }
}

.slider-next,
.slider-prev {
    position: absolute;
    top: 33%;
    z-index: 99;
    @include respond(large) {
        top: 33%;
    }
    @include respond(desktop) {
        top: 40%;
    }
}

.slider-next {
    right: 10px;
    @include respond($large-breakpoint, $max-content-breakpoint) {
        right: -10px;
    }
}

.slider-prev {
    left: 10px;
    @include respond($large-breakpoint, $max-content-breakpoint) {
        left: -10px;
    }
    .icon {
        transform: rotate(180deg);
    }
}

.carousel-header {
    margin-bottom: rem(10px);
    padding-bottom: rem(5px);
    font-size: rem(14px);
    font-family: $font-body;
    line-height: 1.25rem;
    letter-spacing: .025em;
    text-transform: capitalize;
}

//generic slider styles
.vertical-carousel {
    .slider-prev {
        position: relative;
        top: 0;
        left: calc(50% - 15px);
        .icon {
            transform: rotate(-90deg);
            width: 30px;
            height: 30px;
        }
    }

    .slider-next {
        position: absolute;
        top: initial;
        top: auto;
        bottom: -30px;
        left: calc(50% - 15px);
    }
}

.horizontal-carousel {
    position: relative;
    margin-top: rem(20px);
    .grid-tile {
        padding: 0 rem(5px);
    }
}

.slick-dots {
    position: relative;
    top: -10px;
    li {
        margin: 0;
        width: rem(15px);
        height: rem(10px);
        button::before {
            opacity: 1;
            border: $border-width $border-style $icon-pagination-color;
            width: $icon-pagination-width;
            height: $icon-pagination-width;
        }
        &.slick-active button::before {
            background-color: $icon-pagination-color;
            opacity: 1;
        }
    }

}

// hide the giant slide images until the slider is initialized
.carousel-header + div {
    display: none;
    &.slick-initialized {
        display: block;
    }
}

.button-text.slider-prev, .button-text.slider-next{
    color: $color-primary;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    background: transparent;
    border-width: 0;
    padding: 0;
    margin: 0;
    text-decoration: $link-base-text-decoration;
    text-transform: none;
    &:hover {
        color: $link-base-hover-color;
        background-color: transparent;
        text-decoration: $link-base-text-decoration;
    }
    &:focus {
        color: $link-base-hover-color;
        background-color: transparent;
        text-decoration: $link-base-text-decoration;
        box-shadow: none;
    }
}