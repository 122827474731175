$breakpoint-name: 'md';
$breakpoint-name: 'md' !default;
$breakpoint-index: index(map-keys($grid-breakpoints), $breakpoint-name);
$prev-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index - 1);
$next-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index + 1);

$slide-out-animation: left 0.5s cubic-bezier(0, 1, 0.5, 1);

@mixin caret-left() {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-left: 0.3 solid transparent;
    width: 0;
    height: 0;
    display: inline-block;
    margin-bottom: 0.125em;
}

@mixin caret-right() {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    position: absolute;
    right: 0.3em;
    margin-top: 0.55em;
}

@each $size in map-keys($grid-breakpoints) {

    @include media-breakpoint-down($size) {
        .menu-toggleable-left.navbar-toggleable-#{$size} {
            position: fixed;
            left: -100%;
            top: 0;
            bottom: 0;
            transition: $slide-out-animation;
            display: block;
            max-width: 100%;

            &.in {
                max-width: 70%;
                min-width: 70%;
                left: 0;
                z-index: 10;
                .search-mobile {
                    padding: rem(10px);
                    background: #eee;
                    width: 100%;
                    .site-search {
                        width: 100%;
                    }
                    .suggestions {
                        width: 70vw;
                        .suggestions {
                            border: none;
                        }
                    }
                }
            }
        }
    }
}

.multilevel-dropdown .dropdown-item.dropdown > .dropdown-toggle:not(.custom-level-two)::after {
    display: none;
}

@include media-breakpoint-up(lg) {
    .nav-item.dropdown {
        position: static;
        width: 100%;
        
    }

    .multilevel-dropdown .dropdown-item + .dropdown-item {
        border-top: none !important;
        margin-bottom: rem(20px);
    }

    .multilevel-dropdown .dropdown-menu > .dropdown > .dropdown-menu {
        position: static;
        box-shadow: none;
    }

    .dropdown-item.active, .dropdown-item:active,
    .dropdown-item:hover, .dropdown-item:focus {
        background-color: $white;
        color: $pricing-in-usd-color;
    }

    .multilevel-dropdown .dropdown-item {
        padding: 0;
        width: auto;
    }

    .multilevel-dropdown .dropdown-item.dropdown > .dropdown-toggle::after {
        display: none;
    }
}

.navbar.bg-inverse {
    background-color: transparent !important;
    padding: 0;

    .dropdown-menu .dropdown-item:hover,
    .dropdown-menu .dropdown-item:focus {
        background-color: transparent;
    }

    @include media-breakpoint-up($next-breakpoint) {
        .navbar-nav .nav-item + .nav-item {
            margin-left: 0;
        }

        .navbar-nav .nav-link {
            padding: rem(6px) rem(5px);
            white-space: nowrap;
            margin-bottom: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            @include media-breakpoint-only(lg) {
                font-size: rem(12px);
            }

            & + .dropdown-menu.show {
                display: flex;
                flex-wrap: wrap;
                box-shadow: none;
                width: 100vw;
                max-width: 100vw;
                margin-left: calc((100vw - 100%) / -2);
                padding: rem(20px) calc((100vw - 100%) / 2);
                min-height: rem(300px);
                border-top: 1px solid $disabled-grey;
                box-shadow: 0 4px 2px -2px #e0e3e7;
            }
        }
    }
    @include media-breakpoint-up(lg) {
        .promo-cat-text {
            text-align: center;
            font-size: rem(12px);
            padding-bottom: rem(12px);
            color: $color-dark-label;
            display: flex;
            justify-content: center;
            text-transform: none;
        }
        .text {
            display: block;
            margin-left: 0;
            text-align: center;
            width: 100%;
        }
    }
}

@include media-breakpoint-up(lg) {
    .nav-item.dropdown > .dropdown-toggle .text {
        &::after {
            display: inline-block;
            visibility: hidden;
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: "";
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
            display: none;
        }
    }
    .nav-item.dropdown.show > .dropdown-toggle{
        text-decoration: underline;
        text-underline-position: under;
    }
}

.nav-item.dropdown > .dropdown-toggle::after {
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.navbar-expand-lg .navbar-nav.nav-center {
    justify-content: center;
}

.navbar-expand-lg .navbar-nav.nav-spaced {
    justify-content: space-evenly;
}

.navbar-expand-lg .navbar-nav.nav-right {
    justify-content: end;
}

.navbar-expand-lg .navbar-nav {
    @include media-breakpoint-up(lg) {
        justify-content: space-between;
        flex-wrap: nowrap;
    }
}

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
    background-color: $white;
    color: $menu-link;
}

@include media-breakpoint-up($next-breakpoint) {
    .nav-item > .nav-link {
        color: $white;
    }
}

.main-menu.menu-toggleable-left {

    @include media-breakpoint-down($breakpoint-name) {
        background-color: $white;
        z-index: 4;
    }
}

.menu-toggleable-left {
    .close-menu {
        padding: 0 rem(15px);
        background-color: $close-menu-bg;
        border-bottom: 1px solid $grey3;
        flex: 0 0 100%;

        @include media-breakpoint-up($next-breakpoint) {
            display: none;
        }
        .back {
            padding: rem(10px) 0;
        }
        .close-button {
            button {
                position: absolute;
                right: 0;
                width: 30vw;
                left: 70vw;
                height: 100vh;
                top: - rem(60px);
                background: rgba(0,0,0,.3);
                .svg-close {
                    position: absolute;
                    top: rem(16px);
                    left: rem(8px);
                    width: rem(30px);
                    border-radius: rem(30px);
                    height: rem(30px);
                    background: white;
                    z-index: 999;
                }
            }
        }
    }
    @include media-breakpoint-down($breakpoint-name) {
        .level-3 .nav-menu{
            margin-left: - rem(10px);
        }
    }

    .menu-group {
        flex: 0 0 100%;
        @include media-breakpoint-down($breakpoint-name) {
            overflow-y: auto;
            max-height: calc(100vh - 120px);
            overflow-x: hidden;
        }
    }

    li > .close-menu {
        margin-right: 0;
        margin-top: 0;
        margin-left: 0;
    }

    @include media-breakpoint-down($breakpoint-name) {
        .bg-inverse {
            background-color: white !important;
            color: $grey7;
        }
    }
    .create-account-link {
        color: #63666a;
        font-size: inherit;
        cursor: pointer;
        text-decoration: none;
        font-size: rem(13px);
        font-size: .8125rem;
        line-height: rem(15px);
        line-height: .9375rem;
        letter-spacing: .025em;
        font-weight: 400;
        text-transform: none;
    }

    &.in {

        @include media-breakpoint-down($prev-breakpoint) {
            right: 0;
            margin-right: 1.25em; /* 20/16 */
        }

        @include media-breakpoint-down($breakpoint-name) {
            .nav-item + .nav-item {
                border-top: 1px solid $grey2;
            }

            .dropdown {
                display: block;
                position: static;
                padding: 0;
                margin-bottom: 0 !important;
            }

            .dropdown-toggle:not(.custom-level-two) {
                padding-left: 1rem;

                &::after {
                    @include caret-right();
                }
            }

            .nav-item .nav-link {
                padding-left: 1rem;
                display: flex;
                font-size: rem(16px);
                position: relative;
                margin: 0;
                padding: .75rem 1rem;
                .Utility_Tracking {
                    top: 0;
                }
                i.fa, svg {
                    font-size: rem(17px);
                    margin-right: rem(10px);
                }
                span.user-message {
                    line-height: rem(17px);
                }
                &:not(.dropdown-toggle) {
                    .promo-cat-text {
                        display: none;
                    }
                }

                .promo-cat-text {
                    padding-left: rem(5px);
                    text-transform: none;
                    font-size: rem(12px);
                    font-family: $font-body;
                }

                .text {
                    font-size: rem(11px);
                    font-family: $font-heading;
                }
            }

            .show > .dropdown-menu {
                left: 0;
                padding: 0;
                margin-top: -1px;
                &.level-3{
                    padding: rem(6px) 0;
                }
            }
            .dropdown-menu {
                position: absolute;
                left: -100%;
                top: 0;
                width: 100%;
                height: 100%;
                border: 0 none;
                transition: $slide-out-animation;
                display: block;
                &.level-3 {
                    position: static;
                    .subcategory-items {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }
    }
}

.multilevel-dropdown {
    .dropdown-menu {
        top: 84%;
        border: 0;
        border-radius: 0;

        @include media-breakpoint-up($next-breakpoint) {
            box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4);
        }
    }

    .dropdown-item.dropdown > .dropdown-toggle:not(.custom-level-two)::after {
        @include caret-right();
    }

    .dropdown-menu > .dropdown > .dropdown-menu {

        @include media-breakpoint-up(lg) {
            top: -0.65em;
            left: 99%;
        }
    }

    .navbar > .close-menu > .back {
        display: none;
    }

    .close-menu .back {
        .caret-left {
            @include caret-left();
        }
    }

    .dropdown-item {
        @include media-breakpoint-down(md) {
            padding: 0.25rem 16px;
        }
        &.subcategory-items {
            @include media-breakpoint-down($breakpoint-name) {
                overflow-y: auto;
                max-height: calc(100vh - 160px);
            }
        }
        .dropdown-link {
            display: block;
            padding: rem(4px, 32px, 4px, 0);
            font-size: rem(14px);
            line-height: rem(22px);
            margin-bottom: 0;
            white-space: normal;
            @include media-breakpoint-down(md) {
                h5 {
                    display: inline;
                    line-height: 0;
                    font-size: rem(13px);
                }
            }

            &.dropdown-toggle {
                color: $navy;
                text-transform: uppercase;
                font-weight: bold;
                font-size: rem(16px);
                @include media-breakpoint-down(md) {
                    font-size: rem(12px);
                }
            }
            @include media-breakpoint-down($breakpoint-name) {
                font-size: rem(14px);
                margin: 0;
                padding: .75rem 1rem;
                color: $dark-grey;
            }
        }

        + .dropdown-item {
            border-top: 1px solid $close-menu-bg;
        }

        &.top-category {
            font-weight: bold;

            > .nav-link {
                padding-left: 0;
            }
        }
    }
}
.search-mobile {
    padding: 0 rem(10px);
}
.navbar-header
{
    .country-selector, .pull-left .user {
        margin-top: 0;
    }
    .pull-left .user {
        @include media-breakpoint-down($breakpoint-name) {
            padding-left: rem(5px);
            padding-top: rem(13px);
            margin: 0;
            top: rem(5px);
            position: absolute;
            .country-selector {
                margin: 0;
                border: 0;
                width: rem(30px);
                .btn{
                    line-height: normal;
                    padding: 0;
                    &::after {
                        content: none;
                    }
                }
            }

            .Utility_Store_Locator {
                top: -2px;
            }
        }
        @include media-breakpoint-down(xs) {
            padding-top: rem(8px);
        }
        margin-top: rem(17px);
    }
    .minicart {
        @include media-breakpoint-down(xs) {
            margin-top: rem(3px);
        }
    }
    .country-selector {
        .flag-icon {
            line-height: rem(18px);
        }
        .btn {
            padding-right: 0;
            line-height: normal;
            display: flex;
            &::after {
                content: "";
                border-right: 1px solid #e1e1e1;
                font-size: 0.53125rem;
                margin-left: 0.45rem;
                margin-right: 0.15rem;
            }
        }
    }
}
@include media-breakpoint-down($breakpoint-name) {
    .navbar-header {
        .navbar-toggler {
            padding-left: 0;
            padding-top: rem(12px);
            line-height: normal;
        }
    }
}

@include media-breakpoint-down(xs) {
    .navbar-header {
        .navbar-toggler {
            line-height: normal;
            padding-top: rem(5px);
            margin-bottom: 0;
        }
    }
}

.dropdown-item.active, .dropdown-item:active {
    background-color: transparent !important;
}

.main-nav-container {
    z-index: 99;
    top:0;
}
.main-nav-container.position-fixed {
    
    @include media-breakpoint-up(lg) {
        transition:all 2s ease;
        box-shadow: $box-shadow-nav;
        .main-menu {
            margin-top: rem(20px);
        }
        .container {
            transform: scale(80%);
        }
        .suggestions .container {
            transform: scale(1);
        }
        .navbar-header.brand {
            left: 0;
            padding: 0 0 0 20px;
            margin: 0;
            width: 10%;
            top: 50%;
            transform: translateY(-50%);
        }
        .search-for-fixed-header {
            width: 10%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: rem(50px);
            min-width: rem(150px);
            .site-search {
                width: 100%;
                .suggestions.modal{
                    position: fixed;
                    top: 46px !important;
                    height: 55vh;
                    left: -148px;
                }
            }
        }
        .stycky-header-minicart {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: rem(10px);
        }
        .express-loader-container {
            display: none;
        }
    }
    @include media-breakpoint-up(xl) {
        .container {
            transform: scale(70%);
        }
    }
    @media screen and (min-width: 1400px) {
        .container {
            transform: scale(80%);
        }
      }
    @media screen and (min-width: 1400px) {
        .container {
            transform: scale(90%);
        }
      }
      @media screen and (min-width: 1500px) {
        .container {
            transform: scale(100%);
        }
      }
    @include media-breakpoint-down(xs) {
        .navbar-header {
            height: 3.375rem;
        }
        .suggestions.modal {
            top: 0 !important;
            position: absolute;
            height: 100vh;
            left: -10px;
        }
    }
}

@include media-breakpoint-down(md) {
    .custom-level-two {
        position: relative;
        &::before {
            content: '';
            height: 12px;
            border-left: 2px solid $navy;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: rem(20px);
            margin: 0;
        }

        &::after {
            content: '';
            width: 12px;
            border-top: 2px solid $navy;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: rem(15px);
            margin: 0;
        }

        &.active {
            &::before {
                display: none;
            }
        }
    }

    .level-2-open {
        .level-2 {
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 100vh;
        }
        .nav-item .nav-link {
            .text {
                font-size: 13px !important;
            }
        }
    }
    .main-menu .navbar .close-menu button, .main-menu .navbar .close-button button {
        padding: 0;
    }
}
.search-clear-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%) scale(1.5);
    cursor: pointer;
    display: none;
}
