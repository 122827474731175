@import "~base/components/quickView";
@import "../utilities/swatch";
@import "productCommon";
@import '../common/text_styles';
@import '../common/custom_ui_elements';
@import '../components/sizechart';
@import '../product/addToBagModal';

#quickViewModal,
#editProductModal, #editWishlistProductModal {
    .quick-view-dialog,
    .choose-bonus-product-dialog {
        max-width: rem(1090px);
        overflow-y: auto;
        max-height: 100vh;

        /* width */
        &::-webkit-scrollbar {
            width: 5px;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: $medium-grey;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $dark-grey; 
        }
    
        .modal-body {
            max-height: none;
            overflow-y: unset;
            padding-bottom: rem(32px);
            @include media-breakpoint-down(sm) {
                height: calc(100vh - 64px);
                overflow-y: auto;
            }
        }
    
        .selectable-bonus-product-line-item {
            margin-top: 0.2em;
            margin-bottom: 0.2em;
            padding-top: 0.3em;
            padding-bottom: 0.3em;
        }
    
        .beenSelected {
            background-color: $grey2;
        }
    
        .modal-header {
            background-color: transparent;
            border-bottom: 0;
    
            .full-pdp-link {
                color: #{var(--skin-primary-color-1)};
            }
    
            .close {
                font-size: 2rem;
                line-height: 1.5rem;
                opacity: 1;
                &:focus {
                    outline: 1px solid $janie-black;
                }
            }

            .close-icon {
                background-image: $close-svg;
                background-position: 0 0;
                height: 15px;
                width: 15px;
                display: inline-block;
            }
        }
    
        .modal-title {
            font-size: 1em;
        }
    
        .product-name {
            font-size: 1.875em;
        }
    
        .swatch-circle {
            // $white will be replaced by color image background via Javascript
            @include swatch(5rem, $white);
        }
    
        a[disabled] .swatch-circle {
            cursor: not-allowed;
    
            &.color-value.selected::after {
                background-color: $gray-700;
            }
        }
    
        .availablity-container {
            text-align: right;
        }
    
        .availablity-container,
        .size-chart {
            margin-top: 0.938em;
        }
    
        .modal-content {
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 0;
        }
    
        button.close {
            font-size: 1.25em;
        }
    
        .modal-footer {
            display: none !important;
            background-color: $white;
            border: none;
            border-bottom-right-radius: 0.1875rem;
            border-bottom-left-radius: 0.1875rem;
    
            .prices .price {
                font-size: 1.6em;
            }
        }
    
        .prices .sales {
            font-size: rem(20px);
            letter-spacing: rem(1.6px);
        }
    
        .promotions {
            text-align: left;
            color: $red;
            flex-direction: column;
        }
    
        .bonus-summary {
            @include media-breakpoint-down(sm) {
                font-size: 0.625em;
            }
    
            .bonus-product-name {
                @include media-breakpoint-down(sm) {
                    padding: 0;
                }
            }
        }
    
        .pre-cart-products {
            margin-right: 0.125em;
        }
    
        .color-attribute {
            border: none;
            padding: 0;
            background: none;
        }
    
        .non-input-label {
            display: block;
            margin-bottom: 0.5rem;
        }

        .quantity-box {
            margin-top: 1.5rem !important;
        }

        .add-to-cart-box {
            .btn.add-to-cart {
                margin: 0 !important;
                height: 3.375rem !important;
                letter-spacing: 0.05rem;
            }
        }

        .description-and-detail {
            row.details {
                margin-top: 0;
            }
        }
    
        .primary-images {
            .carousel-indicators {
                margin: 0;
                position: static;
                align-items: center;
                li {
                    &.active {
                        border: 1px solid $navy;
                    }
                    opacity: 1;
                    width: 100px;
                    height: 100%;
                    margin: rem(0, 5px);
        
                    img {
                        width: 100%;
                    }
                }
            }
        }
        
        .product-name-sku {
            @include media-breakpoint-down(sm) {
                flex-wrap: wrap;
                justify-content: center;
                text-align: center;
                margin-top: rem(15px);
            }
            .product-name {
                width: 100%;
                font-weight: 400;
                font-size: rem(16px);
                line-height: 23px;
                text-transform: capitalize;
                font-family: $pdp-font-family;
                margin-left: rem(-1px);
                @include media-breakpoint-up(md) {
                    letter-spacing: rem(1.92px);
                    font-size: $pdp-font-size-lg;
                    line-height: 150%;
                }
            }
            .product-number {
                width: 42%;
                padding: 0;
                color: $dark-grey;
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
        }

        .main-content-right {
            .prices {
                margin-left: rem(-1px);
            }
        }
        
        .product-number-rating {
            @include media-breakpoint-up(sm) {
                border-top: 0;
                border-bottom: 0;
        
                .ratings {
                    margin-top: 0;
                }
            }
        
            #pr-reviewsnippet {
                margin-bottom: 0 !important;
                display: none;
            }
        }
        
        .product-detail {
            .attributes {
                .color-attribute {
                    &.size-attribute {
                        margin: 3px -1px;
                        &.unselectable {
                            pointer-events: none;
                            cursor: not-allowed;
                        }
                    }
                }
                .swatch-circle {
                    border: 0;
                    &.color-value {
                        width: rem(40px);
                        height: rem(40px);
                        margin-left: rem(1px);
                        &.selected {
                            border-radius: 0;
                            border: 0;
                            box-shadow: inset 0 0 0 1px $white, 0 0 0 1px $dark-grey;
                            background: transparent;
                            border: 1px solid $white;
                            &::after {
                                display: none;
                            }
                        }
        
                        &:hover,
                        &:focus {
                            border-radius: 0;
                            border: 0;
                            box-shadow: inset 0 0 0 1px $white, 0 0 0 1px $dark-grey;
                            background: transparent;
                            border: 1px solid $white;
                        }
        
                        &.unselectable {
                            opacity: 0.5;
                            border-radius: 0;
                            border: 0;
                            box-shadow: inset 0 0 0 1px $white, 0 0 0 1px $dark-grey;
                            background: transparent;
                            border: 1px solid $white;
                            &::after {
                                border-right: 1px solid $dark-grey;
                                top: rem(4px);
                                right: rem(18px);
                                -ms-transform: skewX(-45deg);
                                transform: skewX(-45deg);
                                height: rem(30px);
                            }
                        }
                    }
                    &.size-value,
                    &.cardAmount-value {
                        min-width: rem(82px);
                        height: rem(34px);
                        line-height: rem(32px);
                        padding: 0;
                        margin-left: 1px;
                        font-size: rem(14px);
                        border: rem(0.5px) solid $dark-grey;
                        text-transform: uppercase;
                        font-size: rem(12px);
                        font-weight: 600;
                        &.selected.selectable {
                            border-color: $navy;
                            background: $navy;
                            color: $white;
                            outline: $navy solid 2px;
                        }
                        &.selectable {
                            &:focus,
                            &:hover {
                                border-color: $navy;
                                background: $navy;
                                color: $white;
                            }
                        }
                    }
                    &.unselectable {
                        color: $disabled-grey;
                        border-color: $disabled-grey;
                        background: #fff;
                        &::after {
                            content: "";
                            display: block;
                            position: absolute;
                            border-right: 2px solid $disabled-grey;
                            top: 0;
                            right: rem(39px);
                            height: 2rem;
                            -ms-transform: skewX(-68deg);
                            transform: skewX(-68deg);
                        }
                    }
                }
        
                .gift-attribute {
                    padding: 4px 4px 4px 0;
                    margin: 0 3.5px;
                    border: 0;
                    background: transparent;
                    .swatch-value {
                        display: block;
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        height: rem(70px);
                        width: rem(100px);
                        min-width: rem(100px);
                        &.selected {
                            box-shadow: inset 0 0 0 1px $white, 0 0 0 1px $dark-grey;
                            border-radius: rem(5px);
                        }
                        &:focus,
                        &:hover {
                            box-shadow: inset 0 0 0 1px $white, 0 0 0 1px $dark-grey;
                            border-radius: rem(5px);
                        }
                    }
                }
            }
        
            .prices-add-to-cart-actions {
                padding: 0;
                @include media-breakpoint-down(sm) {
                    flex-wrap: wrap;
                    justify-content: center;
                }
                .quantity {
                    width: 28%;
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                    }
                }
            
                .add-btn {
                    width: 68%;
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                    }
                }
            }
        
            .custom-select {
                height: rem(52px);
                border-color: $dark-grey;
                border-radius: 0;
                padding: rem(1px, 0, 0, 48px);
                @include media-breakpoint-down(sm) {
                    height: rem(45px);
                }
            }

            .quantity-label{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: rem(25px);
                margin: 0;
            }
        
            .select-styles {
                font-size: rem(12px);
                color: $red;
                line-height: rem(20px);
            }
        
            .add-to-wish-list {
                .Heart {
                    stroke: $black;
                    stroke-width: rem(5px);
                    position: absolute;
                    width: 25px;
                    height: 20px;
                }
                .fa-heart {
                    color: $black;
                }
                .fa-heart,
                .fa-heart-o {
                    font-size: 22px;
                }
            }

            .p-w-r {
                margin-bottom: rem(10px);
            }

            .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-read-and-write,
            .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-stars-reco-reco,
            .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-read-and-write,
            .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-stars-reco-reco {
                margin-top: 5px !important;
                margin-left: 5px !important;

                .pr-snippet-write-review-link {
                    text-decoration: underline !important;
                    text-decoration-color: gray !important;
                }
            }

            .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-stars-container,
            .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-stars-container {
                @include media-breakpoint-down(sm) {
                    display: block;
                    text-align: center;
                    margin-top: 20px;
                    display: flex !important;
                    justify-content: center !important;
                    width: 100% !important;
                    margin-left: 0 !important;
                }
            }
        
            .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-compact .pr-snippet-read-and-write,
            .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-read-and-write {
                @include media-breakpoint-down(sm) {
                    display: block;
                    text-align: center;
                    display: flex !important;
                    justify-content: center !important;
                    width: 100% !important;
                    margin-top: rem(10px);
                }
            }

            .p-w-r .pr-accessible-btn,
            .p-w-r .pr-review-snapshot .pr-review-snapshot-simple .pr-review-snapshot-snippets .pr-snippet-read-and-write a {
                color: $white !important;
            }
        
            .prices {
                @include media-breakpoint-down(sm) {
                    text-align: center;
                }
            }

            .p-w-r .pr-snippet-stars-reco-inline.pr-snippet-minimal .pr-snippet-stars-container {
                margin-left: 0 !important;
            }
        
            .collapsible-xs {
                .title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0;
                    font-family: $font-body;
                    font-size: rem(12px);
                    line-height: normal;
                    padding: rem(16px) 0;
                    border-bottom: 1px solid $medium-grey !important;
                    margin: 0;
                    letter-spacing: rem(1.92px);
                    font-weight: 600;
                    color: $dark-grey !important;
                    @include media-breakpoint-down(sm) {
                        font-size: rem(13px);
        
                        &::after {
                            display: none;
                        }
                    }
                    .Arrow_small.down-arrow {
                        width: rem(10px) !important;
                        height: rem(10px) !important;
                    }
                    &:focus-visible {
                        outline: 1px solid $janie-black !important;
                    }
                }
                .content {
                    display: none;
                }
        
                &.active {
                    .title {
                        border-bottom: 0 !important;
                        .Arrow_small.down-arrow {
                            transform: rotate(-90deg);
                        }
                    }
                    .content {
                        display: block;
                    }
                }
                .product-detail-content {
                    p {
                        display: flex;
                        margin-bottom: 0;
                        padding-left: 0;
                    }
                    .title {
                        font-size: rem(14px);
                        border-top: 0 !important;
                        padding: 0;
                        margin: 0;
                        margin-left: rem(-18px);
                    }
                    .tab-content {
                        padding-left: 0;
                        font-size: rem(12px);
                        padding-bottom: rem(20px);
                        border-bottom: 1px solid $medium-grey;
                        line-height: rem(18px);
                    }
                }
            }
        
            .add-to-wish-list {
                border: 0;
                padding: 0;
                background: transparent;
                text-decoration: underline;
                font-size: rem(14px);
                position: absolute;
                right: rem(25px);
                top: rem(4px);
                z-index: 2;
            }
        
            .availability-wishlist {
                max-width: 100%;
            }
        
            .size-chart {
                .Size_Chart {
                    width: rem(12px);
                    height: rem(12px);
                    position: relative;
                    top: -2px;
                }
                button.normal {
                    background-color: transparent;
                    border: 0;
                    font-size: rem(12px);
                    letter-spacing: rem(0.96px);
                    text-decoration: none !important;
                    font-weight: 600;
                    span {
                        text-decoration: underline;
                    }
                }
            }
        
            .non-input-label {
                font-size: rem(12px);
                line-height: rem(20px);
                margin-bottom: 6px;
                letter-spacing: rem(1.92px);
                font-weight: 600;
                .attr-value {
                    letter-spacing: rem(1.12px);
                    font-weight: 400;
                }
            }
        
            @include media-breakpoint-down(xs) {
                .cart-and-ipay .btn {
                    margin: 15px 0 0;
                }
            }

            @include media-breakpoint-down(sm) {
                .product-breadcrumb .breadcrumb {
                    display: inline;
                    border-top: 0;
                    .breadcrumb-item {
                        display: inline;
                        a {
                            display: none;
                            &.home {
                                width: rem(30px) !important;
                                text-indent: unset !important;
                                &::before {
                                    content: "...";
                                    display: inline-block;
                                    margin-left: 1rem;
                                    text-decoration: none;
                                }
                            }
                        }
        
                        & + .breadcrumb-item {
                            font-size: rem(12px);
                            line-height: rem(24px);
                            padding-left: 0;
                            &::before {
                                display: none;
                            }
                        }
                    }
                }
            }
            .quantity-label {
                &:disabled {
                    opacity: 0.35;
                    background-color: $white;
                    color: $dark-grey;
                }
            }
            .description {
                margin-left: 0;
                padding-left: rem(25px);
                font-size: rem(12px);
                margin-bottom: rem(15px);
                letter-spacing: rem(0.96px);
                li {
                    list-style-type: none;
                    text-indent: rem(-10px);
                    &::before {
                        content: "-";
                        margin-right: rem(5px);
                    }
                    &:first-child {
                        margin-left: rem(-25px);
                        text-indent: 0;
                        &::before {
                            display: none;
                        }
                    }
                    &:not(:last-child){
                        padding-bottom: rem(8px);
                    }
                }
            }
            .select-styles {
                font-size: rem(14px);
            }
        }

        .product-set-detail {
            h1.product-name {
                color: $navy;
                font-family: $font-heading;
                font-size: rem(15px);
                line-height: rem(18px);
                font-weight: bold;
                letter-spacing: 0.14em;
                text-transform: uppercase;
                text-align: center;
                @include media-breakpoint-up(md) {
                    font-size: 1.125rem;
                    line-height: 1.5rem;
                    letter-spacing: 0.28em;
                    text-align: left;
                }
            }
            .set-item {
                padding: 0;
                margin: 0.8125rem 0 0 0;
                .product-name a {
                    font-size: rem(16px);
                    line-height: rem(24px);
                    font-family: $font-heading;
                    padding-top: 0.9375rem;
                    text-decoration: none;
                    text-align: center;
                    @include media-breakpoint-up(md) {
                        letter-spacing: 0.28em;
                        text-align: left;
                    }
                }
                img {
                    width: 100%;
                    height: auto;
                }
                @include media-breakpoint-down(xs) {
                    .cart-and-ipay {
                        padding-bottom: 0;
                        .btn {
                            margin: 0;
                        }
                    }
                }
                .prices {
                    @include media-breakpoint-down(sm) {
                        text-align: left;
                    }
                }
                .attribute {
                    @include media-breakpoint-down(sm) {
                        margin-bottom: rem(20px);
                    }  
                }
            }
            .product-set-image {
                padding-top: 1.25rem;
            }
            .set-main-btn {
                @include media-breakpoint-down(xs) {
                    padding: 0 !important;
                }
            }
        }

        // Gift Card PDP
        .gift-card-wrapper {
            &.attributes {
                border-top: 1px solid $disabled-grey;
                margin-top: 0.9375rem;
                position: relative;
            }
        }

        .gift-pdp {
            .product-name-sku {
                .product-name {
                    width: 60%;
                }
            }
            .row[data-attr="cardAmount"] {
                margin-bottom: rem(20px);
            }
        }
        
    }
    .attributes {
        margin-top: rem(25px);
        .row {
            margin-bottom: rem(22px);
            &.collapsible-xs {
                margin-bottom: 0;
            }
        }
        .attribute {
            margin-top: 0;
            &.quantity {
                label {
                    margin-bottom: rem(6px);
                }
            }
        }

        @include media-breakpoint-up(md) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .main-content-right {
        @include media-breakpoint-up(md) {
            flex: 0 0 45%;
            max-width: 45%;
            margin-left: rem(35px);
        }
    }
}
#quickViewModal {
    .price {
        font-size: rem(20px);
        letter-spacing: rem(1.6px);
        color: #63666a;
        .strike-through {
            color: #63666a;
            letter-spacing: .28em;
        }
        .strike-price {
            letter-spacing: .28em;
        }
        span {
            font-weight: 850 !important;
        }
    }
    .third-party-not-available-quickView {
        padding-bottom: rem(18px);
        position: static;
        text-align: left;
        display: block !important;
    }
    .third-party-not-available {
        display: none !important;
    }
    .product-ship-info {
        line-height: rem(18px);
        .content-asset {
            b {
                font-family: $pdp-font-family !important;
            }
        }
        .tooltip-custom-class {
            width: rem(16px) !important;
            height: rem(16px) !important;
            fill: #333 !important;
        }
    }
    .quantity-wrapper div.quantity-error, .quantity-wrapper div.quantity-warning {
        color: $dark-grey !important;
        @include media-breakpoint-up(lg) {
            white-space: nowrap;
        }
    }
    .promotions.sizes-range > .promotion-content {
        padding: 0 rem(15px);
    }
    .non-input-label .attr-value {
        font-size: rem(14px);
        letter-spacing: rem(1.12px);
        font-weight: 400;
        line-height: rem(21px);
    }
}
